<template lang="pug">
  include ../../../src/plugins/pug/template
  v-container(fluid).px-6.py-6
    v-card.mb-6
      v-card-title
        h5.font-weight-bold.text-h5.text-typo {{ $t('profile.profileTitle') }}

      v-tabs(background-color="#F7F9FB" :slider-size="3").mt-2
        v-tabs-slider(color="#2F6FED")
        v-tab(
          v-for="tab in tabs"
          :key="`tab_${tab.id}`"
          :ripple="false"
          :to="{ name: tab.routeName }"
        ) {{ $t(tab.title) }}

      router-view

</template>
<script>
import ChangePassword from '@/views/Pages/Account/Widgets/ChangePassword'
import ProfileOverview from '@/views/Pages/Profile/ProfileOverview'
import { mapGetters } from 'vuex'

export default {
  components: {
    ProfileOverview,
    ChangePassword
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          title: 'profile.settings',
          routeName: 'profile-settings'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['isShowNavigation'])
  },
  created() {
    if (this.isShowNavigation) {
      this.tabs.unshift({
        id: 0,
        title: 'profile.information',
        routeName: 'profile-information'
      })
    }

  }
}
</script>
