import store from '@/store'

let role = {}

export const initPermissions = () => {
  const profile = store.state.profile.profile

  role = {
    NAAUWorker: profile.type_user === 'NAAU worker',
    MIUWorker: profile.type_user === 'MIU worker',
    RegistryUser: profile.type_user === 'Registry user'
  }
}

export const access = typeAccess => {
  if (typeAccess === 'public') {
    return true
  }

  switch (typeAccess) {
    case 'viewPageVL': return role.NAAUWorker || role.RegistryUser

    case 'moderationActionNAAUWorker': return role.NAAUWorker
    case 'moderationActionMIUWorker': return role.MIUWorker

    case 'viewInformationTab': return role.NAAUWorker || role.MIUWorker
    case 'viewEmployeeTab': return role.NAAUWorker
    case 'viewBranchTab': return role.NAAUWorker
    case 'viewMTZTab': return role.NAAUWorker
    case 'canUploadFileNAAUVersion': return role.NAAUWorker
    case 'canViewFileNAAUVersion': return role.NAAUWorker || role.MIUWorker

    case 'viewPageRegistryUser': return role.NAAUWorker || role.MIUWorker

    case 'canAddCertificate': return role.NAAUWorker || role.MIUWorker
    case 'canAddCertificateType': return role.NAAUWorker || role.MIUWorker
    case 'canCreateCharacteristicsKtz': return role.NAAUWorker || role.MIUWorker

    case 'canViewDetailedButton': return role.NAAUWorker || role.MIUWorker

    default: return true
  }
}
