import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { registeredMeasuringLaboratory as endpoint } from '@/configs/endpoints'
import { questionGroupVL as questionGroup } from '@/configs/constants'
import { access } from '@/configs/permissions'

const store = 'registeredMeasuringLaboratory'

export default {
  state: () => ({
    measuringLaboratory: {},

    measuringLaboratoryBranchArray: [],
    measuringLaboratoryBranch: {},

    measuringLaboratoryEmployeeArray: [],
    measuringLaboratoryEmployee: {},

    measuringLaboratoryWorkLineArray: [],
    measuringLaboratoryWorkLine: {},
    equipmentType: [],

    measuringLaboratoryStatementBranchArray: [],
    measuringLaboratoryStatementBranch: {},

    measuringLaboratoryStatementBranchQuestion: [],
    isReadonlyStatementBranch: true
  }),

  actions: {
    async getRegisteredMeasuringLaboratory (context, id) {
      const response = await api.get(endpoint.registeredMeasuringLaboratory(id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratory', data: response.data })
      }
    },

    async getRegisteredMeasuringLaboratoryBranchList (context, data) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryBranchList(data.id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryBranchArray', data: response.data.results })
      }
    },

    async getRegisteredMeasuringLaboratoryBranch (context, ids) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryBranch(ids))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryBranch', data: response.data })
      }
    },

    async getRegisteredMeasuringLaboratoryEmployeeList (context, data) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryEmployeeList(data.id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryEmployeeArray', data: response.data.results })
      }
    },

    async getRegisteredMeasuringLaboratoryEmployee (context, ids) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryEmployee(ids))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryEmployee', data: response.data })
      }
    },

    async getRegisteredMeasuringLaboratoryWorkLineArray (context, ids) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryWorkLineArray(ids))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryWorkLineArray', data: response.data })
        if (!response.data.length) context.dispatch('clearRegisteredMeasuringLaboratoryWorkLine')
      }
    },

    async getRegisteredMeasuringLaboratoryWorkLine (context, ids) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryWorkLine(ids))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryWorkLine', data: response.data })
      }
    },

    clearRegisteredMeasuringLaboratoryWorkLine (context) {
      context.commit('SET_DATA', { store, state: 'measuringLaboratoryWorkLine', data: {} })
    },

    // moderation statement branch in registered VL

    async getVLStatementBranchList (context, data) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryStatementBranchList(data.id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryStatementBranchArray', data: response.data.results })
      }
    },

    async getVLStatementBranch (context, ids) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryStatementBranch(ids))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryStatementBranch', data: response.data })
      }
    },

    async updateVLStatementBranch (context, data) {
      const response = await api.patch(endpoint.registeredMeasuringLaboratoryStatementBranch(data.ids), { body: data.body } )
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryStatementBranch', data: response.data })
      }
    },

    async getVLStatementBranchQuestion (context, ids) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryStatementBranchQuestion(ids))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_BY_GROUP', {
          store,
          state: 'measuringLaboratoryStatementBranchQuestion',
          data: response.data,
          groupConfig: {
            1: questionGroup.branchVL[1]
          }
        })
      }
    },

    async getVLStatementBranchQuestionFile (context, id) {
      const response = await api.get(endpoint.registeredMeasuringLaboratoryStatementBranchQuestionFile(id))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_FILE_BY_GROUP', {
          store,
          state: 'measuringLaboratoryStatementBranchQuestion',
          data: response.data,
          groupConfig: {
            2: questionGroup.branchVL[2]
          }
        })
      }
    },

    async setVLStatementBranchDecision (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateRegisteredMeasuringLaboratoryStatementBranchQuestion(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'measuringLaboratoryStatementBranchQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    async setVLStatementBranchDecisionFile (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateRegisteredMeasuringLaboratoryStatementBranchQuestionFile(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'measuringLaboratoryStatementBranchQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    setReadonlyVLStatementBranch (context) {
      let isReadonly = true
      let isModerationBranches = context.state.measuringLaboratory.has_moderation_branches
      let status = context.state.measuringLaboratoryStatementBranch.statement_step

      if (access('moderationActionNAAUWorker') && isModerationBranches && status === 2) {
        isReadonly = false
      }

      context.commit('SET_DATA', { store, state: 'isReadonlyStatementBranch', data: isReadonly })
    }

  }
}