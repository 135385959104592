<template lang="pug">
  router-view
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState({
      // isLoadApp: state => state.app.isLoad
    }),
    isAuth () {
      return this.$auth.isAuth()
    }
  },
  mounted () {
    window.chatwootSettings = {
      position: 'left'
    }
    // this.isAuth && this.getDirectories()
  },
  methods: {
    ...mapActions(['getDirectories'])
  }
};
</script>
