import btn from './uk/btn'
import vehicleCharacteristic from './uk/vehicleCharacteristic'
import notification from './uk/notification'
import statuses from './uk/statuses'

export default {
  ...btn,
  ...vehicleCharacteristic,
  ...notification,
  ...statuses,
  projectName: 'Реєстр сертифікатів транспортних засобів',
  app: 'Додаток',
  messages: 'Повідомлення',
  settings: 'Налаштування',
  admin: 'Адміністратор',
  save: 'Зберегти',
  certificationList: 'Усі видані сертифікати',
  certificationListKtz: 'Усі видані сертифікати типу КТЗ',
  createCertificationType: 'Створення характеристики КТЗ',
  characteristic: 'Характеристики',
  certificateType: 'Сертифікат типу',
  pageCountTable: 'Кількість рядків на сторінці',
  count: 'Кількість',
  search: 'Пошук',
  number: 'Номер',
  car: 'Авто',
  vim: 'VIN',
  status: 'Статус',
  actions: 'Дія',
  allCertifications: 'Усі сертифікати',
  allCertificationsTypeKtz: 'Усі сертифікати типу КТЗ',
  states: 'Статистика',
  credentialsError: 'Невірний логін або пароль',
  mainInfo: 'Загальна інформація',
  welcome: 'Вхід до застосунку',
  welcome2: 'Щоб розпочати роботу у застосунку, виконайте вхід у свій акаунт.',
  signBy2F: 'Введіть код двухфакторної авторизації',
  createBy2F: 'Відскануйте QR-код та введіть код двухфакторної авторизації',
  code: 'Код',
  signInWelcome: 'Продуктивного та гарного вам дня',
  certificationRegistry: 'Реєстр сертифікації',
  authorization: 'Аворизація',
  uploadPhoto: 'Додати документ',
  saveDocument: 'Зберегти документ',
  signIn: 'Увійти',
  enterEmailPassword: 'Заповніть поля Логін та Пароль для авторизації',
  login: 'Логін',
  email: 'E-mail',
  password: 'Пароль',
  tmpPassword: 'Тимчасовий пароль',
  infoCertification: 'Огляд сертифікату',
  noImage: 'Без фотографій',
  uploadAdditionalPhotos: 'Загрузити ще документ',
  accreditationCertificateVersionNAAU: 'Атестат з акредитації примірник НААУ',
  uploadAccreditationCertificateVersionNAAU: 'Завантажити "Атестат з акредитації примірник НААУ"',
  menu: {
    menu: 'Меню',
    dashboard: 'Головна',
    profile: 'Особистий кабінет',
    certifications: 'Сертифікати',
    certificationsType: 'Сертифікати типу',
    certificationAgency: 'Орган сертифікації',
    measuringLaboratory: 'Випробувальна лабораторія',
    registryUser: 'Користувачі реєстру',
  },
  'user-profile': 'Профіль',
  'registryUser': 'Користувачі',
  'user': 'Користувачі реєстру',
  'user-info': 'Інформація користувача',
  'user-edit': 'Редагування користувача',
  "profile-information": 'Інформація',
  "profile-settings": 'Налаштування',
  infoCertificationsTypeKtz: 'Сертифікат Типу',

  first_name: 'Ім\'я',
  last_name: 'Прізвище',
  middle_name: 'По-батькові',
  full_name: 'ПІБ користувача',
  phone_number: 'Номер телефону',
  organization: 'Організація',
  position: 'Посада',
  registeredDate: 'Дата реєстрації',
  lastVisitDate: 'Дата останнього відвідування',

  profile: {
    profileTitle: 'Особистий кабінет',
    information: 'Інформація профілю',
    settings: 'Налаштування профілю',

    personal_info: "Особиста інформація",
    service_info: "Службова інформація",

    changePassword: 'Зміна пароля',
    currentPassword: 'Поточний пароль',
    newPassword: 'Новий пароль',
    confirmNewPassword: 'Повторіть новий пароль',
    updatePassword: 'Оновити пароль',
    passwordRecommendations: 'Рекомендації до пароля',
    pleaseFollow: 'Щоб отримати надійний пароль, дотримуйтесь цієї інструкції: ',
    oneSpecialCharacters: 'Один спеціальний символ',
    minCharacters: 'Мінімум 8 символів',
    oneNumber: 'Одне число (рекомендовано 2)',
    changeOften: 'Міняйте його часто'
  },
  noData: 'Немає даних',
  certificate: 'Сертифікат',
  registration_number: 'Унікальний номер документа',
  identification_type_code: 'Познаки для ідентифікації типу КТЗ',
  certification_agency: 'Назва організації яка видала документ',
  vehicle_mark: 'Торгове найменування (марка) виробника',
  vehicle_model: 'Торгове(і) найменування КТЗ',
  vehicle_mark_type: 'Тип',
  author : 'Автор',
  vehicle : 'Транспорт',
  branch_office : 'Місце видання сертифікату',
  manufacturer_factory : 'Найменування і місцезнаходження складальних підприємств',
  manufacturer_address : 'Найменування і місцезнаходження виробника',
  representative : 'Найменування та місцезнаходження уповноваженого представника виробника (за наявності)',
  identification_place : 'Місце розміщення познак',
  max_amount_approved_vehicles : 'Кількість одиниці затверджена сертифікатом типу',
  limitationDate: 'Сертифікат типу дійсний до',
  certificateTypeDateEnd : 'Сертифікат типу дійсний до',
  issue_date : 'Дата видання сертифікату',
  approved_type : 'Тип обмеження затвердження',
  status_document : 'Статус документу',
  correspond_tech_rules : 'Відповідність технічним приписам додатка',
  correspond_mark_type : 'Відповідність зазначеного типу КТЗ',
  typeCer: 'Тип сертифікату',
  fullNumber: 'Номер сертифікату',
  vehicleModel: 'Модель транспортного засобу',
  createdAt: 'Створено',
  modifiedAt: 'Змінено',
  vinCode: 'Vin код',
  identificationMethod: 'Ідентифікаційний метод',
  manufacturerCountry: 'Країна виробник',
  vehicleCategory: 'Категорія транспортного засобу',
  identificationPlace: 'Ідентифікаційне місце',
  dateEnd: 'Дата закінчення',
  errorSearchDate: 'Кінцева дата повинна перевищувати попередню',
  issueDate: 'Дата видання',
  typeApproval: 'Тип затвердження',
  issuer: 'Видано ПІБ',
  issuerPosition: 'Видано посада',
  dashboard: {
    statistic: {
      certificatesTitle: 'Загальна кількість сертифікатів відповідності',
      certificatesTypeTitle: 'Загальна кількість сертифікатів Типу',
      certificationAgencyTitle: 'Наповнювачі реєстру',
    },
    chart: {
      certificate_type: 'Сертифікати типу',
      certificates: 'Сертифікати',
      total_certificate_type: 'Сертифікати типу загальна к-сть',
      total_certificates: 'Загальна кількість',
      total_vehicle_certificates: 'Сертифікати загальна к-сть',
    },
    chartTitle: {
      title: 'Статистика за {certificate_type}',
      certificate_type: 'Сертифікатами типу',
      certificates: 'Сертифікатами',
      total_certificate_type: 'Загальною к-стю сертифікатів типу',
      total_certificates: 'Загальною к-стю всіх сертифікатів',
      total_vehicle_certificates: 'Загальною к-стю сертифікатів',
    }
  },
  wrongDate: 'Невірна дата',
  noInformation: '-- Інформація відсутня --',
  filter: {
    title: 'Фільтрація',
    certification_agency: 'Орган сертифікації',
    activity: 'Статус',
    vin_code: 'VIN-код',
    status_document: 'Статус',
    certificate_type: 'Статистика за',
    dateTypeLabel: 'Показати статистику',
    search: 'Пошук',
    date: {
      interval: 'ДД.ММ.РРРР - ДД.ММ.РРРР',
      year: 'РРРР',
      month: 'ММ.РРРР',
      day: 'ДД.ММ.РРРР'
    }
  },
  searchModeration: {
    dates: 'Пошук між датами',
    status: 'Статус'
  },
  moderation: {
    changeStatusTitle: 'На який статус змінити?'
  },
  VL: {
    measuringLaboratoryList: 'Випробувальні лабораторії',
    tabs: {
      information: 'Інформація про ВЛ',
      branch: 'Представництва',
      employee: 'Співробітники',
      mtz: 'Технічне забезпечення',
    },
    group: {
      insuranceFund: 'Страховий фонд',
      directorInformation: 'Інформація директора ВЛ',
      directorDocuments: 'Документи директора ВЛ',
      sectionDocuments: 'Документи згідно розділу',
      mtz: 'Матеріально-технічне забезпечення'
    },
    question: {
      information: {
        author: 'ПІБ директора',
        director_full_name: 'ПІБ директора',
        director_passport_serial: 'Паспортні дані (номер серія) Директора',
        director_passport_inn: 'РНОКПП (ІПН) Директора',
        name: 'Повна назва організації',
        short_name: 'Скорочена назва організації',
        EDPROU: 'Код ЄДРПОУ',
        phone_numbers: 'Номери телефонів',
        landline_phone: 'Телефон стаціонарний',
        email: 'Електронна поштова адреса',
        web_resource_link: 'Посилання на веб-ресурс',
        address: 'Адреса',
        accreditation_certificate_number: 'Номер атестату акредитації НААУ',
        accreditation_certificate_date_end: 'Дата закінчення дії акредитації НААУ',
        passport: 'Паспорт директора',
        itn: 'РНОКПП (ІПН)',
        extractFromTheRegisterEDRPOU: 'Витяг з реєстру ЄДРПОУ',
        passportVL: 'Паспорт ВЛ',
        legalDocumentsVL: 'Правовстановлюючі документи',
        appointmentOrderOfManager: 'Наказ про призначення керівника',
        accreditationCertificateNAAU: 'Атестат про аккредитацію НААУ',
        sphereAccreditation: 'Сфери акредитації ВЛ',
        date_last_revision: 'Дата останньої перевірки',
        date_next_revision: 'Дата наступної перевірки',
        accreditationCertificateVersionNAAU: 'Атестат з акредитації примірник НААУ',
      },
      branch: {
        name: 'Номер дільниці',
        number: 'Кількість комплексів обладнання',
        address: 'Адреса відділення',
        allow_categories: 'Категорії КТЗ',
        sphereAccreditation: 'Сфери акредитації ВЛ',
        passportVL: 'Паспорт ВЛ',
        dataMeasuringMethods: 'Дані про оцінку методів випробувань'
      },
      employee: {
        position: 'Займана посада в органі з оцінки відповідності',
        first_name: 'Ім\'я',
        last_name: 'Прізвище',
        middle_name: 'По-батькові',
        serial: 'Номер паспорта',
        inn: 'РНОКПП (ІПН)',
        specialization: 'Спеціальність та кваліфікація',
        direction_raising_qualification: 'Напрям підвищення кваліфікації',
        passport: 'Документ що посвідчує особу',
        itn: 'РНОКПП (ІПН)',
        diploma: 'Диплом про вищу освіту',
        certificationTraining: 'Документ підвищення кваліфікації',
      },
    },
    mtz: {
      workLine: 'Лінія {lineNumber}',
      isEmpty: 'Матеріально-технічне забезпечення відсутнє...'
    },
    equipment: {
      equipment_type: 'Тип обладнання',
      equipment_model: 'Модель',
      passport_number: 'Номер паспорту сертифіката',
      serial_number: 'Серійний номер',
      date_previous_verification: 'Дата попередньої перевірки',
      date_next_verification: 'Дата наступної перевірки',
      serial_number_photo: 'Фото серійного номеру прибора',
      serial_number_photo_file: 'Серійний номер прибору',
    },
    statementBranch: {
      hasModerationBranches: 'У ВЛ є дільниці на модерації',
      statementTitle: 'Дільниці на перевірці НААУ',
      activeTitle: 'Активні дільниці',
      statementBackLink: 'До списку дільниць',
      filterBranchType: 'Статус дільниці',
      filterOption: {
        all: 'Всі',
        active: 'Активні',
        statement: 'На модерації'
      }
    }
  },
  OC: {
    certificationAgencyList: 'Органи сертифікації',
    tabs: {
      information: 'Інформація про ОС',
      employee: 'Співробітники',
      branch: 'Представництва',
    },
    group: {
      insuranceFund: 'Страховий фонд',
      directorInformation: 'Інформація директора ОС',
      directorDocuments: 'Документи директора ОС',
      sectionDocuments: 'Документи згідно розділу',
      education: 'Інформація про освіту',
      qualification: 'Інформація про підвищення кваліфікації',
      educationDocument: 'Документи про освіту та підвищення кваліфікації',
      systematic: 'Системні співробітники',
      unsystematic: 'Несистемні співробітники',
    },
    question: {
      information: {
        author: 'ПІБ директора',
        director_full_name: 'ПІБ директора',
        director_passport_serial: 'Паспортні дані (номер серія) Директора',
        director_passport_inn: 'РНОКПП (ІПН) Директора',
        name: 'Повна назва організації',
        short_name: 'Скорочена назва організації',
        EDPROU: 'Код ЄДРПОУ',
        code: 'Номер призначенний ООВ (Органу з сертифікації) згідно нотифікації ЄК ООН',
        phone_numbers: 'Номери телефонів',
        landline_phone: 'Телефон стаціонарний',
        web_resource_link: 'Посилання на веб-русурс',
        email: 'Електронна поштова адреса',
        order_number: 'Номер наказу МІУ',
        order_date: 'Дата наказу МІУ',
        accreditation_certificate_number: 'Номер атестату акредитації НААУ',
        accreditation_certificate_date_start: 'Дата початку дії акредитації НААУ',
        accreditation_certificate_date_end: 'Дата закінчення дії акредитації НААУ',
        address: 'Адреса',
        allow_categories: 'Категорії КТЗ',
        type_insurance: 'Тип страхування',
        text_type_insurance: 'Тип страхування',
        sum_insurance_fund: 'Сума страхового фонду (грн)',
        number_insurance_contract: '№ договору страхування відповідальності',
        end_date_insurance_contract: 'Кінцевий строк дії договору',
        accreditationCertificateNAAU: 'Атестат про акредитацію НААУ',
        insurance: 'Страхування',
        orderMIU: 'Наказ МІУ',
        spheresAccreditationProject: 'Проект "Сфери акредитації"',
        appointmentOrderOfManager: 'Наказ про призначення керівника',
        extractFromTheRegisterEDRPOU: 'Витяг з реєстру ЄДРПОУ',
        passport: 'Паспорт директора',
        itn: 'РНОКПП (ІПН)',
        date_last_revision: 'Дата останньої перевірки',
        date_next_revision: 'Дата наступної перевірки',
        accreditationCertificateVersionNAAU: 'Атестат з акредитації примірник НААУ',
      },
      employee: {
        position: 'Займана посада в органі з оцінки відповідності',
        first_name: 'Ім\'я',
        last_name: 'Прізвище',
        middle_name: 'По-батькові',
        serial: 'Номер паспорта',
        inn: 'РНОКПП (ІПН)',
        education_document_number: 'Документ, що підтверждує здобуття вищої освіти (серія, номер)',
        educational_institution: 'Найменування навчального закладу',
        education_document_issued_date: 'Дата видачі документу, що підтверджує здобуття вищої освіти',
        specialization: 'Спеціальність та кваліфікація',
        direction_raising_qualification: 'Напрям підвищення кваліфікації',
        raising_qualification_document_number: 'Документ, отриманий за результатами підвищення кваліфікації (серія, номер)',
        raising_qualification_date: 'Дата видачі документу, отриманого за результатами підвищення кваліфікації',
        experience_years: 'Досвід виконання робіт з оцінки відповідності продукції, років',
        identityDocument: 'Документ що посвідчує особу',
        passport: 'Документ що посвідчує особу',
        itn: 'РНОКПП (ІПН)',
        educationDocument: 'Диплом про вищу освіту',
        raisingQualificationDocument: 'Документ підвищення кваліфікації',
      },
      branch: {
        address: 'Адреса представництва',
        head_full_name: 'ПІБ керівника',
        phone_number: 'Телефон керівника',
        email: 'E-mail керівника'
      }
    }
  },
  question: {
    wrong: 'Невірно',
    yourComment: 'Ваш коментар',
    comment: 'Коментар',
    placeholder: 'Введіть коментар'
  },
  tabsStatus: {
    active: 'Зареєстровані',
    moderation: 'На модерації',
    rejected: 'Анульовані / Відхилені'
  },
  identifier: 'Ідентифікатор',
  EDPROU: 'Код ЄДРПОУ',
  legalName: 'Юридична назва організації',
  accreditationCertificateNumber: '№ атестату про акредитацію',
  accreditationExpirationDate: 'Дата закінчення акредитації',
// Vuetify components translations
  $vuetify: {
    badge: 'Значок',
    close: 'Закрити',
    dataIterator: {
      noResultsText: 'Відповідних записів не знайдено',
      loadingText: 'Завантаження елементів...'
    },
    dataTable: {
      itemsPerPageText: 'Рядки на сторінці:',
      ariaLabel: {
        sortDescending: 'Відсортовано за спаданням.',
        sortAscending: 'Відсортовано за зростанням.',
        sortNone: 'Не відсортовані.',
        activateNone: 'Активуйте, щоб прибрати сортування.',
        activateDescending: 'Активуйте, щоб сортувати за спаданням.',
        activateAscending: 'Активуйте, щоб сортувати за зростанням.'
      },
      sortBy: 'Сортувати за'
    },
    dataFooter: {
      itemsPerPageText: 'Елементів на сторінці:',
      itemsPerPageAll: 'Усі',
      nextPage: 'Наступна сторінка',
      prevPage: 'Попередня сторінка',
      firstPage: 'Перша сторінка',
      lastPage: 'Остання сторінка',
      pageText: '{0}-{1} of {2}'
    },
    datePicker: {
      itemsSelected: '{0} вибрано',
      nextMonthAriaLabel: 'Наступний місяць',
      nextYearAriaLabel: 'Наступний рік',
      prevMonthAriaLabel: 'Попередній місяць',
      prevYearAriaLabel: 'Попередній рік',
      dateStart: 'Дата початку',
      dateEnd: 'Дата кінця'
    },
    noDataText: 'Дані відсутні',
    carousel: {
      prev: 'Previous visual',
      next: 'Next visual',
      ariaLabel: {
        delimiter: 'Carousel slide {0} of {1}'
      }
    },
    calendar: {
      moreEvents: '{0} більше'
    },
    fileInput: {
      counter: '{0} файли',
      counterSize: '{0} файлів ({1} загалом)'
    },
    timePicker: {
      am: 'AM',
      pm: 'PM'
    },
    pagination: {
      ariaLabel: {
        wrapper: 'Навігація пагінацією',
        next: 'Наступна сторінка',
        previous: 'Попередня сторінка',
        page: 'Перейти на сторінку {0}',
        currentPage: 'Поточна сторінка, Сторінка {0}'
      }
    }
  },
  variant: 'Варіант',
  version: 'Версія',
  mark: 'Марка',
  model: 'Модель',
  category: 'Категорія',
  class: 'Клас',
  countDoors: 'Кількість дверей',
  countSeats: 'Кількість сидінь',
  countAxles: 'Кількість осей',
  countWheels: 'Кількість колес',
  vehicleDrive: 'Привід авто',
  driveAxles: 'Провідні осі',
  wheelBase: 'Колісна база',
  length: 'Довжина',
  width: 'Ширина',
  height: 'Висота',
  equippedWeight: 'Маса КТЗ у спорядженому стані',
  engineManufacturer: 'Виробник двигуна',
  engineCode: 'Код двигуна',
  cylinderArrangement: 'Розташування циліндрів',
  countCylinders: 'Кількість циліндрів',
  engineCapacity: 'Робочий об\'єм',
  fuelType: 'Тип палива',
  enginePower: 'Потужність двигуна',
  engineRpm: 'Обертаючий момент',
  maxSpeed: 'Максимальна швидкість',
  units: {
    year: 'рік',
    years: 'рік(ів)',
    ton: 'т',
    kg: 'кг',
    people: 'чоловік',
    pieces: 'шт',
    meter: 'м',
    cubicMeter: 'м³',
    cubicSM: 'cм³',
    knot: 'узел',
    mm: 'мм',
    speed: 'км/г',
    kw: 'кВт',
    power: 'В',
    horsepower: 'к.с',
    miles: 'Милі',
    squareMeter: 'м²',
    volt: 'В',
    mile: 'мілі',
    km: 'км',
    litre: 'л',
    megaPascal: 'мПа',
    kiloPascal: 'кПа',
    newton: 'Н',
    kilonewton: 'кН',
    rpm: 'об/хв',
    tpm: 'разів/хв',
    c: '°C',
    degree: '°',
    percent: '%',
    second: 'c',
    naturCoef: 'K, 1/m',
    linrCoef: 'N, %',
    coef: 'K',
    dB: 'дБ',
    dBA: 'дБ(А)',
    radius: 'R',
    address: {
      city: 'м. {city}',
      region: '{region}',
      street: 'вул. {street}',
      flat: 'кв. {flat}'
    }
  },
  markType: 'Тип марки',
  controlledAxisTrack: 'Колія керованої осі',
  anotherAxlesTrack: 'Колія решти коліс',
  countDriveAxles: 'Рушійні осі кількість',
  countControlledAxles: 'Управлійні осі кількість',
  grossWeight: 'Максимальна технічно припустима повна маса',
  maxCombineWeight: 'Технічно припустима максимальна маса комбінації',
  internationalPermissibleWeight: 'Вибір правила для припустимих мас',
  registrationMaxCombineWeightLegal: 'Вибір Реєстраційна/експлуатаційна для макс. припустима маса комбінації КТЗ',
  maxCombineWeightLegal: 'Реєстраційна/експлуатаційна максимально припустима маса комбінації КТЗ',
  permissibleWeight: 'Реєстраційна/експлуатаційна максимально припустима повна маса',
  registrationPermissibleWeight: 'Вибір Реєстраційна/експлуатаційна для припустимої маси',
  ratioEnginePowerToWeight: 'Відношення максимальної потужності до маси КТЗ у спорядженому стані',
  vehicle_category: 'Категорія ТЗ',
  countStandingPlaces: 'Кількість місць для пасажирів, які стоять',
  trailerMaxWeightWithBrakes: 'Максимальна маса причепа за наявності робочої системи гальмування',
  trailerMaxWeightWithoutBrakes: 'Максимальна маса причепа за відсутності робочої системи гальмування',
  displacementSaddleCouplingDeviceMax: 'Зміщення сідельно-зчіпного пристрою відносно заднього мосту, макс',
  displacementSaddleCouplingDeviceMin: 'Зміщення сідельно-зчіпного пристрою відносно заднього мосту, мін',
  distanceBetweenFrondAndCouplingDevice: 'Відстань між переднім габаритом КТЗ та центром зчіпного пристрою',
  posteriorOverhang: 'Задній звис',
  axisDrivePneumaticSuspension: 'На рушійній(их) осі (осях) застосовано пневматичну підвіску або еквівалентні пристрої',
  pressureInBrakeTrailer: 'Тиск у магістралі гальмівної системи причепа',
  couplingDeviceNumber: 'Номер затвердження або знак затвердження зчіпного пристрою (у разі нанесення)',
  canTransportDangerousGood: 'Затвердження конструкції щодо перевезення небезпечних вантажів',
  engineWorkingPrinciple: 'Робочий принцип:',
  transmissionType: 'Тип трансмісії',
  engineEuroRate: 'Євро норма',
  environmentalNormCode: 'Код екологічної норми',
  bootSpaceLength: 'Довжина завантажувального простору',
  inspectionResults: 'Результати випробувань',
  resultSmoke: 'Димність (скоригований коефіцієнт поглинання)',
  gasInspectionRMinCoLevel: 'Випробування газу: Мінімальний вміст СО',
  gasInspectionRMaxTemperature: 'Випробування газу: Максимальна температура',
  noiseInspectionMeasurementMode: 'За частоти обертання, хв-1',
  inspectionResultsNoise: 'Рівень шуму',
  noiseInspectionLegalAct: 'Рівень шуму нормативний документ',
  noiseInspectionMeasurementResult: 'Рівень шуму на нерухомому КТЗ',
  nothingFound: 'Нічого не знайдено',
  document: 'Документ',
  carcassType: 'Тип кузова',
  color: 'Колір',
  home: 'Головна',
  certificationAgency: 'Орган сертифікації',
  certificationAgencyList: 'Усі ОС',
  'oc-statement': 'Модерація',
  'oc-registered': 'Зареєстровані',
  'oc-statement-information': 'Інформація про ОС',
  'oc-registered-information': 'Інформація про ОС',
  'oc-statement-employee-list': 'Співробітники',
  'oc-registered-employee-list': 'Співробітники',
  'oc-statement-employee-item': 'Співробітник',
  'oc-registered-employee-item-systematic': 'Співробітник',
  'oc-registered-employee-item-unsystematic': 'Співробітник',
  'oc-statement-branch-list': 'Представництва',
  'oc-registered-branch-list': 'Представництва',
  'oc-statement-branch-item': 'Представництво',
  'oc-registered-branch-item': 'Представництво',
  measuringLaboratoryList: 'Усі ВЛ',
  'vl-information': 'Інформація про ВЛ',
  'vl-branch-list': 'Представництва',
  'vl-branch-item': 'Представництво',
  createCertification: 'Свторення сертифікату типу',
  createAt: 'Дата створення',
  date_end: 'Дата закінчення',
  date_create: 'Дата створення',
  lastWeek: 'Попередній тиждень',
  lastMonth: 'Попередній місяць',
  choosePeriod: 'Обрати період',
  dateFrom: 'Дата з',
  dateTo: 'Дата до',
  'vl-statement-information': 'Інформація про ВЛ',
  'vl-registered-information': 'Інформація про ВЛ',
  'vl-statement-branch-list': 'Представництва',
  'vl-registered-branch-list': 'Представництва',
  'vl-statement-branch-item': 'Представництво',
  'vl-registered-branch-item': 'Представництво',
  'vl-registered-mtz': 'МТЗ',
  'vl-registered-employee-list': 'Співробітники',
  'vl-registered-employee-item': 'Співробітник',
  createCertificateType: 'Створення сертифікату типу',
  createCertificate: 'Додати сертифікат відповідності',
  vehicleInfo: 'Інформація про ТЗ',
  yes: 'Так',
  no: 'Ні',
  vehicleComplianceCertificateNumber: 'Порядковий номер сертифіката відповідності КТЗ',
  personWhoApprovedCertificate: 'ПІБ особи, що затвердила Сертифікат відповідності',
  positionOfPersonWhoApprovedCertificate: 'Посада особи, що затвердила Сертифікат відповідності',
  certificateIssuingLocation: 'Місце видання сертифіката',
  lastName: 'Прізвище',
  firstName: 'Імʼя',
  middleName: 'По-батькові',
  typeCertificate: 'Тип сертифікату',
  certificateNumber: 'Номер сертифіката',
  certificateIssueDate: 'Дата видачі сертифіката, що зазначено вище',
  designation: 'Найменування',
  name: 'Назва',
  manufacturerBrand: '0.1. Торгове найменування (марка) виробника',
  type02: '0.2. Тип',
  variant02: '0.2. Варіант',
  version02: '0.2. Версія',
  vehicleTradeName021: '0.2.1. Торгове найменування КТЗ',
  category04: '0.4. Категорія',
  manufacturerNameAndLocation05: '0.5. Найменування і місцезнаходження виробника',
  placementAndAttachmentMethodOfRequiredPlates: '0.6. Місце розміщення та метод закріплення обов’язкових табличок',
  chassisIdentificationNumberPlacement: 'Розміщення ідентифікаційного номера на шасі',
  authorizedRepresentativeNameAndLocation: 'Найменування та місцезнаходження уповноваженого представника виробника (за наявності)',
  vehicleIdentificationNumber: 'Ідентифікаційний номер КТЗ (VIN)',
  specialSymbols: 'Не типовий VIN код',
  This_password_is_too_common: 'Новий пароль надто поширений',
  Old_and_new_passwords_are_the_same: 'Старий і новий паролі співпадають. Вкажіть новий пароль відмінний від старого',
  automaticallyGenerated: 'Автоматично згенеровані',
  manuallyEnteredCertificates: 'Сертифікати внесені вручну',
  This_password_is_entirely_numeric: 'Новий пароль складається тільки з цифр',
  Incorrect_password: 'Старий пароль невірний',
  requiredChangePassword: 'Перший вхід. Будь ласка, змініть Ваш пароль.',
  Incorrect_email_or_password: 'Невірний логін або пароль',
  address: {
    address: 'Адреса',
    country: 'Країна',
    region: 'Область',
    district: 'Район',
    city: 'Місто',
    street: 'Вулиця',
    building: 'Будинок',
    apartment: 'Квартира',
    zipCode: 'Поштовий індекс'
  },
  certificationFiles: {
    document_file: 'Сертифікат відповідності',
    certificate_appendix: 'Додаток до сертифікату'
  },
  noFile: 'Документ відсутній',
  The_password_is_too_similar_to_the_username: 'Новий пароль дуже схожий на логін',
  users: {
    title: 'Користувачі',
    create: 'Додати користувача',
    edit: 'Редагувати користувача',
    infoTitle: 'Інформація про користувача',
    infoSubtitle: 'Дані користувача',
    form: {
      title: 'Введіть дані користувача',
      subtitle: 'Поля помічені зірочкою обов\'язкові для заповнення'
    },
    changePasswordApproveTitle: 'Ви впевнені, що хочете змінити пароль?',
    changePasswordApproveSubtitle: 'У разі підтвердження користувачу прийде лист з новим паролем',
    changeStatusTitle: 'Змінити статус',
    changeStatusSubtitle: 'Виберіть новий статус користувача',
    userNeverLogin: 'Користувач ніколи не входив в систему',
  },
  ownerType: 'Тип представника',
  publicCertificateApprove: 'Опублікувати Сертифікат відповідності?',
  editNotWillPossible: 'Після його публікації внесення змін буде неможливо',
  conformityAssessmentAuthority: 'Орган ООВ що проводив оцінку відповідності',
  conformityAssessmentAuthorityName: 'Найменування органа ООВ що проводив оцінку відповідності',
  baseCertificateConformityAssessmentAuthority: 'Орган ООВ що проводив оцінку відповідності базового сертифікату',
  baseCertificateConformityAssessmentAuthorityName: 'Найменування органа ООВ що проводив оцінку відповідності базового сертифікату',
}
