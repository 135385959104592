import Vue from 'vue'
import Vuex from 'vuex'

import mutations from '@/store/mutations'
import getters from '@/store/getters'
import App from './modules/app'
import Directory from './modules/directory'
import Profile from './modules/profile'
import Certificate from './modules/certificate'
import Dashboard from '@/store/modules/dashboard'
import Vehicle from "@/store/modules/vehicle"
import CertificationAgency from './modules/certificationAgency'
import RegisteredCertificationAgency from './modules/registeredCertificationAgency'
import MeasuringLaboratory from './modules/measuringLaboratory'
import RegisteredMeasuringLaboratory from './modules/registeredMeasuringLaboratory'
import User from './modules/user'


Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app: App,
    directory: Directory,
    profile: Profile,
    certificate: Certificate,
    dashboard: Dashboard,
    vehicle: Vehicle,
    certificationAgency: CertificationAgency,
    registeredCertificationAgency: RegisteredCertificationAgency,
    measuringLaboratory: MeasuringLaboratory,
    registeredMeasuringLaboratory: RegisteredMeasuringLaboratory,
    user: User
  },
  mutations,
  getters
})

export default store
