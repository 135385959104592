const apiVersion = 'api/v1'

export const auth = {
  totp: {
    create: `${ apiVersion }/accounts/totp/create/`,
    verify: `${ apiVersion }/accounts/totp/verify/`,
    login: `${ apiVersion }/accounts/totp/login/`,
  },
  u2f: {
    registration: {
      options: `${ apiVersion }/accounts/u2f/create/`,
      registration: `${ apiVersion }/accounts/u2f/create/`,
    },
    authorization: {
      options: `${ apiVersion }/accounts/u2f/login/`,
      authorization: `${ apiVersion }/accounts/u2f/login/`,
    }
  },
  general: {
    login: `${ apiVersion }/accounts/login/`
  },
  phone: {
    login: ''
  }
}

export const directory = {
  region: `${apiVersion}/directory/general/region/`,
  cityByRegion: id => `${apiVersion}/directory/general/city/?region=${ id }`,
  city: id => `${apiVersion}/directory/general/city/${ id }/`,
  vehicleModel: id => `${apiVersion}/directory/vehicle_model/${ id }/`,
  vehicleModelByMark: id => `${apiVersion}/directory/vehicle_model/?vehicle_mark=${ id }`,
  vehicleModificationByModel: id => `${apiVersion}/directory/vehicle_modification/?vehicle_model=${ id }`,
  vehicleCategory: `${ apiVersion }/directory/vehicle_category/`,
  certificationAgency: `${ apiVersion }/directory/certification_agency/`
}

export const directories = [
  { endpoint: `${ apiVersion }/directory/vehicle_mark/`, state: 'vehicleMark'},
  { endpoint: `${ apiVersion }/directory/vehicle_model/`, state: 'vehicleModel'},
  { endpoint: `${ apiVersion }/directory/certification_agency/`, state: 'certificationAgency'},
  { endpoint: `${ apiVersion }/directory/cylinder_arrangement/`, state: 'cylinderArrangement'},
  { endpoint: `${ apiVersion }/directory/engine_manufactory_company/`, state: 'engineManufacturerCompany'},
  { endpoint: `${ apiVersion }/directory/manufacturer_country/`, state: 'manufacturerCountry'},
  { endpoint: `${ apiVersion }/directory/engine_working_principle/`, state: 'engineWorkingPrinciple'},
  { endpoint: `${ apiVersion }/directory/fuel_type/`, state: 'fuelType'},
  { endpoint: `${ apiVersion }/directory/identification_method/`, state: 'identificationMethod'},
  { endpoint: `${ apiVersion }/directory/identification_place/`, state: 'identificationPlace'},
  { endpoint: `${ apiVersion }/directory/laboratory_branch_office/`, state: 'laboratoryBranchOffice'},
  { endpoint: `${ apiVersion }/directory/measuring_laboratory/`, state: 'measuringLaboratory'},
  { endpoint: `${ apiVersion }/directory/vehicle_axis/`, state: 'vehicleAxis'},
  { endpoint: `${ apiVersion }/directory/vehicle_category/`, state: 'vehicleCategory'},
  { endpoint: `${ apiVersion }/directory/vehicle_color/`, state: 'vehicleColor'},
  { endpoint: `${ apiVersion }/directory/vehicle_door_location/`, state: 'vehicleDoorLocation'},
  { endpoint: `${ apiVersion }/directory/trailer_type_brake_connector/`, state: 'trailerTypeBrakeConnector'},
  { endpoint: `${ apiVersion }/directory/carcass_type/`, state: 'carcassType'},
  { endpoint: `${ apiVersion }/directory/engine_euro/`, state: 'engineEuro'},
  { endpoint: `${ apiVersion }/directory/legal_act/`, state: 'legalAct'},
  { endpoint: `${ apiVersion }/directory/euro_code/`, state: 'euroCode'},
  { endpoint: `${ apiVersion }/directory/transmission/type/`, state: 'transmissionType'}
]

export const profile = {
  me: `${ apiVersion }/accounts/me/`,
  changePassword: `${ apiVersion }/accounts/password/`,
}

export const vehicle = {
  vehicle: id => `${ apiVersion }/certifications/vehicle/${ id ? id+'/' : ''}`,
}

export const certificate = {
  certificate: id => `${ apiVersion }/certifications/certification/vehicle_certificate/${ id ? id+'/' : ''}`,
  manualCertificate: id => `${ apiVersion }/certifications/registry/vehicle_certificate/${ id ? id+'/' : ''}`,
  certificateType: id => `${ apiVersion }/certifications/approval_type_certificate/${ id ? id+'/' : ''}`,
  certificateTypeCharacteristic: id => `${ apiVersion }/certifications/approval_type_certificate/${ id }/appendix/`,
  certificateTypeCharacteristicDependency: id => `${ apiVersion }/directory/appendix_dependency/${ id ? id+'/' : ''}`,
  uploadFilesCertificateType: id => `${ apiVersion }/certifications/approval_type_certificate/${ id }/upload_file/`,
  noiseInspection: id => `${ apiVersion }/certifications/approval_type_certificate/${ id }/appendix/noise_inspection/`,
  emissionInspection: id => `${ apiVersion }/certifications/approval_type_certificate/${ id }/appendix/emission_inspection/`,
  vehicleConsumption: id => `${ apiVersion }/certifications/approval_type_certificate/${ id }/appendix/vehicle_consumption/`,
}

export const certificateAgency = {
  getCertificationAgencyArray: `${apiVersion}/moderation/statement_certification_agency/`,
  getRegisteredCertificationAgencyArray: `${apiVersion}/moderation/certification_agency/`,
  certificationAgency: id => `${apiVersion}/moderation/statement_certification_agency/${id}/`,
  uploadMediaCertificationAgency: id => `${apiVersion}/moderation/statement_certification_agency/${id}/upload_file/`,
  removeMediaCertificationAgency: id => `${apiVersion}/moderation/statement_certification_agency/${id}/remove_file/{file_id}/`,
  certificationAgencyQuestion: id => `${apiVersion}/moderation/statement_certification_agency/${id}/question/`,
  certificationAgencyQuestionFile: id => `${apiVersion}/moderation/statement_certification_agency/${id}/question/file/`,
  certificationAgencyQuestionDirectorFile: id => `${apiVersion}/moderation/statement_certification_agency/${id}/question/director_file/`,
  updateCertificationAgencyQuestion: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/question/${params.questionId}/`,
  updateCertificationAgencyQuestionFile: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/question/file/${params.questionId}/`,
  updateCertificationAgencyQuestionDirectorFile: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/question/director_file/${params.questionId}/`,
  certificationAgencyEmployeeList: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/statement_certification_employee/`,
  certificationAgencyEmployeeQuestion: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/statement_certification_employee/${params.employeeId}/question/`,
  updateCertificationAgencyEmployeeQuestion: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/statement_certification_employee/${params.employeeId}/question/${params.questionId}/`,

  certificationAgencyEmployeeQuestionFile: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/statement_certification_employee/${params.employeeId}/question/file/`,
  updateCertificationAgencyEmployeeQuestionFile: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/statement_certification_employee/${params.employeeId}/question/file/${params.questionId}/`,

  certificationAgencyBranch: id => `${apiVersion}/moderation/statement_certification_agency/${id}/statement_certification_branch/`,
  certificationAgencyBranchQuestion: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/statement_certification_branch/${params.branchId}/question/`,
  updateCertificationAgencyBranchQuestion: params => `${apiVersion}/moderation/statement_certification_agency/${params.id}/statement_certification_branch/${params.branchId}/question/${params.questionId}/`
}

export const registeredCertificationAgency = {
  registeredCertificationAgency: id => `${apiVersion}/moderation/certification_agency/${id}/`,
  registeredCertificationAgencyEmployeeList: id => `${apiVersion}/moderation/certification_agency/${id}/certification_user/`,
  registeredCertificationAgencyEmployee: params => `${apiVersion}/moderation/certification_agency/${params.id}/certification_user/${params.employeeId}/`,
  registeredCertificationAgencyEmployeeListUnsystematic: id => `${apiVersion}/moderation/certification_agency/${id}/statement_certification_user/`,
  registeredCertificationAgencyEmployeeUnsystematic: params => `${apiVersion}/moderation/certification_agency/${params.id}/statement_certification_user/${params.employeeId}/`,
  registeredCertificationAgencyBranchList: id => `${apiVersion}/moderation/certification_agency/${id}/certification_branch/`,
  registeredCertificationAgencyBranch: params => `${apiVersion}/moderation/certification_agency/${params.id}/certification_branch/${params.branchId}/`,
}

export const measuringLaboratory = {
  measuringLaboratoryArray: `${apiVersion}/moderation/statement_measuring_laboratory/`,
  registeredMeasuringLaboratoryArray: `${apiVersion}/moderation/measuring_laboratory/`,
  measuringLaboratory: id => `${apiVersion}/moderation/statement_measuring_laboratory/${id}/`,

  uploadMediaMeasuringLaboratory: id => `${apiVersion}/moderation/statement_measuring_laboratory/${id}/upload_file/`,
  removeMediaMeasuringLaboratory: id => `${apiVersion}/moderation/statement_measuring_laboratory/${id}/remove_file/{file_id}/`,

  measuringLaboratoryQuestion: id => `${apiVersion}/moderation/statement_measuring_laboratory/${id}/question/`,
  measuringLaboratoryQuestionFile: id => `${apiVersion}/moderation/statement_measuring_laboratory/${id}/question/file/`,
  measuringLaboratoryQuestionDirectorFile: id => `${apiVersion}/moderation/statement_measuring_laboratory/${id}/question/director_file/`,
  updateMeasuringLaboratoryQuestion: params => `${apiVersion}/moderation/statement_measuring_laboratory/${params.id}/question/${params.questionId}/`,
  updateMeasuringLaboratoryQuestionFile: params => `${apiVersion}/moderation/statement_measuring_laboratory/${params.id}/question/file/${params.questionId}/`,
  updateMeasuringLaboratoryQuestionDirectorFile: params => `${apiVersion}/moderation/statement_measuring_laboratory/${params.id}/question/director_file/${params.questionId}/`,

  measuringLaboratoryBranch: id => `${apiVersion}/moderation/statement_measuring_laboratory/${id}/statement_laboratory_branch/`,
  measuringLaboratoryBranchQuestion: params => `${apiVersion}/moderation/statement_measuring_laboratory/${params.id}/statement_laboratory_branch/${params.branchId}/question/`,
  updateMeasuringLaboratoryBranchQuestion: params => `${apiVersion}/moderation/statement_measuring_laboratory/${params.id}/statement_laboratory_branch/${params.branchId}/question/${params.questionId}/`,

  measuringLaboratoryBranchQuestionFile: params => `${apiVersion}/moderation/statement_measuring_laboratory/${params.id}/statement_laboratory_branch/${params.branchId}/question/file/`,
  updateMeasuringLaboratoryBranchQuestionFile: params => `${apiVersion}/moderation/statement_measuring_laboratory/${params.id}/statement_laboratory_branch/${params.branchId}/question/file/${params.questionId}/`,
}

export const registeredMeasuringLaboratory = {
  registeredMeasuringLaboratory: id => `${apiVersion}/moderation/measuring_laboratory/${id}/`,
  registeredMeasuringLaboratoryBranchList: id => `${apiVersion}/moderation/measuring_laboratory/${id}/laboratory_branch/`,
  registeredMeasuringLaboratoryBranch: params => `${apiVersion}/moderation/measuring_laboratory/${params.id}/laboratory_branch/${params.branchId}/`,
  registeredMeasuringLaboratoryEmployeeList: id => `${apiVersion}/moderation/measuring_laboratory/${id}/laboratory_user/`,
  registeredMeasuringLaboratoryEmployee: params => `${apiVersion}/moderation/measuring_laboratory/${params.id}/laboratory_user/${params.employeeId}/`,
  registeredMeasuringLaboratoryWorkLineArray: params => `${apiVersion}/moderation/measuring_laboratory/${params.id}/laboratory_branch/${params.branchId}/working_line/`,
  registeredMeasuringLaboratoryWorkLine: params => `${apiVersion}/moderation/measuring_laboratory/${params.id}/laboratory_branch/${params.branchId}/working_line/${params.workLineId}/`,

  registeredMeasuringLaboratoryStatementBranchList: id => `${apiVersion}/moderation/measuring_laboratory/${id}/statement_laboratory_branch/`,
  registeredMeasuringLaboratoryStatementBranch: params => `${apiVersion}/moderation/measuring_laboratory/${params.id}/statement_laboratory_branch/${params.branchId}/`,

  registeredMeasuringLaboratoryStatementBranchQuestion: params => `${apiVersion}/moderation/measuring_laboratory/${params.id}/statement_laboratory_branch/${params.branchId}/question/`,
  updateRegisteredMeasuringLaboratoryStatementBranchQuestion: params => `${apiVersion}/moderation/measuring_laboratory/${params.id}/statement_laboratory_branch/${params.branchId}/question/${params.questionId}/`,

  registeredMeasuringLaboratoryStatementBranchQuestionFile: params => `${apiVersion}/moderation/measuring_laboratory/${params.id}/statement_laboratory_branch/${params.branchId}/question/file/`,
  updateRegisteredMeasuringLaboratoryStatementBranchQuestionFile: params => `${apiVersion}/moderation/measuring_laboratory/${params.id}/statement_laboratory_branch/${params.branchId}/question/file/${params.questionId}/`
}

export const statistic = {
  generalStatistic: `${apiVersion}/core/general_static/`,
  generalChart: `${apiVersion}/core/general_charts/`
}

export const user = {
  user: id => `${apiVersion}/accounts/registry_users/${ id ? id+'/' : ''}`,
}
