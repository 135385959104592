import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { measuringLaboratory as endpoint } from '@/configs/endpoints'
import { access } from '@/configs/permissions'
const store = 'measuringLaboratory'
import { i18n } from '@/plugins/vue-i18n.js'
import { questionGroupVL as questionGroup } from '@/configs/constants'
import router from '@/router'

export default {
  state: () => ({
    measuringLaboratoryArray: [],

    measuringLaboratory: {
      files: []
    },
    measuringLaboratoryQuestion: [],
    measuringLaboratoryQuestionFile: [],

    measuringLaboratoryBranchArray: [],
    measuringLaboratoryBranchQuestion: [],

    isReadonlyStatement: true
  }),
  getters: {
    measuringLaboratoryBranchArray: state => {
      return state.measuringLaboratoryBranchArray.map(e => {
        let city = e.address.city ? `${i18n.t('units.address.city', { city: e.address.city } )}, ` : ''
        let region = e.address.region ? `${i18n.t('units.address.region', { region: e.address.region } )}, ` : ''
        let street = e.address.street ? i18n.t('units.address.street', { street: e.address.street } ) : ''
        let house = e.address.house ? `${ e.address.house }, ` : ''
        let flat = e.address.flat ? `${i18n.t('units.address.flat', { flat: e.address.flat } )}, ` : ''
        let index = e.address.index ? e.address.index : ''
        e.address = `${city} ${region} ${street} ${house} ${flat} ${index}`
        return e
      })
    }
  },
  actions: {
    async getMeasuringLaboratoryList (context, filter) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      const response = await api.get(`${endpoint.measuringLaboratoryArray}?${ filter }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryArray', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
    },

    async getRegisteredMeasuringLaboratoryList (context, filter) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      const response = await api.get(`${endpoint.registeredMeasuringLaboratoryArray}?${ filter }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryArray', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
    },

    async getMeasuringLaboratory (context, id) {
      const response = await api.get(endpoint.measuringLaboratory(id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratory', data: response.data })
      }
    },

    async updateMeasuringLaboratory (context, data) {
      const response = await api.patch(endpoint.measuringLaboratory(data.id), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratory', data: response.data })
        if (response.data.statement_step === 4) {
          router.push({ name: 'measuringLaboratoryRegistered', params: { id: data.id } })
        }
      }
    },

    async updateMediaMeasuringLaboratory (context, data) {
      const response = await api.post(endpoint.uploadMediaMeasuringLaboratory(data.id), { body: data.body })
      if (await requestValidation(response)) {
        context.dispatch('getMeasuringLaboratory', data.id)
      }
    },

    async deleteMediaMeasuringLaboratory (context, data) {
      const response = await api.post(endpoint.removeMediaMeasuringLaboratory(data.id), { body: data.body })
      if (await requestValidation(response)) {
        context.dispatch('getMeasuringLaboratory', data.id)
      }
    },

    // VL information ----------------------------------------------------------

    async getMeasuringLaboratoryQuestion (context, id) {
      const response = await api.get(endpoint.measuringLaboratoryQuestion(id))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_BY_GROUP', {
          store,
          state: 'measuringLaboratoryQuestion',
          data: response.data,
          groupConfig: {
            1: questionGroup.infoVL[1],
            2: questionGroup.infoVL[2]
          }
        })
      }
    },

    async getMeasuringLaboratoryQuestionFile (context, id) {
      const response = await api.get(endpoint.measuringLaboratoryQuestionFile(id))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_FILE_BY_GROUP', {
          store,
          state: 'measuringLaboratoryQuestion',
          data: response.data,
          groupConfig: {
            4: questionGroup.infoVL[4]
          }
        })
      }
    },

    async getMeasuringLaboratoryQuestionDirectorFile (context, id) {
      const response = await api.get(endpoint.measuringLaboratoryQuestionDirectorFile(id))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_FILE_BY_GROUP', {
          store,
          state: 'measuringLaboratoryQuestion',
          data: response.data,
          groupConfig: {
            3: questionGroup.infoVL[3]
          }
        })
      }
    },

    async setMeasuringLaboratoryDecision (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateMeasuringLaboratoryQuestion(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'measuringLaboratoryQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    async setMeasuringLaboratoryDecisionFile (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateMeasuringLaboratoryQuestionFile(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'measuringLaboratoryQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    async setMeasuringLaboratoryDecisionDirectorFile (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateMeasuringLaboratoryQuestionDirectorFile(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'measuringLaboratoryQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    // VL Branch ---------------------------------------------------------------

    async getMeasuringLaboratoryBranchList (context, id) {
      const response = await api.get(endpoint.measuringLaboratoryBranch(id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'measuringLaboratoryBranchArray', data: response.data })
      }
    },

    async getMeasuringLaboratoryBranchQuestion (context, ids) {
      const response = await api.get(endpoint.measuringLaboratoryBranchQuestion(ids))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_BY_GROUP', {
          store,
          state: 'measuringLaboratoryBranchQuestion',
          data: response.data,
          groupConfig: {
            1: questionGroup.branchVL[1]
          }
        })
      }
    },

    async getMeasuringLaboratoryBranchQuestionFile (context, id) {
      const response = await api.get(endpoint.measuringLaboratoryBranchQuestionFile(id))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_FILE_BY_GROUP', {
          store,
          state: 'measuringLaboratoryBranchQuestion',
          data: response.data,
          groupConfig: {
            2: questionGroup.branchVL[2]
          }
        })
      }
    },

    async setMeasuringLaboratoryBranchDecision (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateMeasuringLaboratoryBranchQuestion(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'measuringLaboratoryBranchQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    async setMeasuringLaboratoryBranchDecisionFile (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateMeasuringLaboratoryBranchQuestionFile(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'measuringLaboratoryBranchQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },


    setReadonlyVLStatement (context) {
      let isReadonly = true
      console.log(context.state.measuringLaboratory)
      let status = context.state.measuringLaboratory.statement_step

      switch (true) {
        case access('moderationActionNAAUWorker'):
          isReadonly = status !== 3
          break
        // case access('moderationActionMIUWorker'):
        //   isReadonly = status !== 7
        //   break
        default:
          isReadonly = true
          break
      }
      context.commit('SET_DATA', { store, state: 'isReadonlyStatement', data: isReadonly })
    }

  }
}
