import { certificate as endpoint } from '@/configs/endpoints'

import notify from '@ananasbear/notify'

import api from '@/plugins/api'
import i18n from '@/plugins/vue-i18n'

import requestValidation from '@/configs/requestValidation'

const store = 'certificate'

export default {
  state: () => ({
    certificates: {},
    certificateType: {},
    certificatesType: {},
    certificateTypeCharacteristic: {},
    certificateTypeCharacteristicDependency: [],
    certificate: {},
    manualCertificate: {},
    certificateCharacteristicEmissionInspection: [],
    certificateCharacteristicVehicleConsumption: [],
    certificateCharacteristicNoiseInspection: [],
    statusDocument: [
      { name: 'Затверджено', id: 1 },
      { name: 'У затвердженні типу відмовлено', id: 2 },
      { name: 'Затвердження типу відкликано', id: 3 }
    ],
    isLoad: false,
    isLoadCharacteristic: false,
  }),
  actions: {
    async getCertificates (context, filter) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      const params = new URLSearchParams()
      for (let field in filter) {
        filter[field] && params.append(field, filter[field])
      }
      const response = await api.get(`${ endpoint.certificate() }?${ params }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificates', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
      return response.code
    },
    async getManualCertificates (context, filter) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      const params = new URLSearchParams()
      for (let field in filter) {
        filter[field] && params.append(field, filter[field])
      }
      const response = await api.get(`${ endpoint.manualCertificate() }?${ params }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificates', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
      return response.code
    },
    async getManualCertificate (context, id) {
      const response = await api.get(`${ endpoint.manualCertificate(id)}`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificate', data: response.data })
        return response.data
      } return false
    },
    async updateManualCertificate (context, data) {
      const response = await api.patch(`${ endpoint.manualCertificate(data.id)}`, { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificate', data: response.data })
        return response.data
      } return false
    },
    async getCertificatesType (context, filter) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      const params = new URLSearchParams()
      for (let field in filter) {
        filter[field] && params.append(field, filter[field])
      }
      const response = await api.get(`${ endpoint.certificateType() }?${ params }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificatesType', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
      return response.code
    },
    async getCertificate (context, id) {
      const response = await api.get(`${ endpoint.certificate(id)}`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificate', data: response.data })
        return response.data
      } return false
    },
    async uploadFilesCertificateType ({ commit }, data) {
      commit('SET_DATA', { store, state: 'isLoading', data: true })
      const response = await api.post(endpoint.uploadFilesCertificateType(data.id), { body: data.body })
      commit('SET_DATA', { store, state: 'isLoading', data: false })
      return response
    },
    async getCertificateType (context, id) {
      const response = await api.get(`${ endpoint.certificateType(id)}`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificateType', data: response.data })
        return response.data
      } return false
    },
    async createCertificatesType (context, body) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })

      const response = await api.post( endpoint.certificateType(), {body: body})

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificatesType', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
      return response
    },
    async getCharacteristicCertificatesTypeDependency (context, id) {
      const response = await api.get(`${ endpoint.certificateTypeCharacteristicDependency()}?vehicle_categories=${id}`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificateTypeCharacteristicDependency', data: response.data })
        return response.data
      } return false
    },
    async getCharacteristicCertificatesType (context, id) {
      const response = await api.get(`${ endpoint.certificateTypeCharacteristic(id)}`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificateTypeCharacteristic', data: response.data })
        return response.data
      } return false
    },
    async addCharacteristicCertificatesType (context, data) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })

      const response = await api.post( endpoint.certificateTypeCharacteristic(data.id), {body: data.body})

      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
      return response
    },
    async updateCharacteristicCertificatesType (context, data) {
      context.commit('SET_DATA', { store, state: 'isLoadCharacteristic', data: true })
      const response = await api.patch( endpoint.certificateTypeCharacteristic(data.id), {body: data.body})

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificateTypeCharacteristic', data: response.data })
        notify.success(i18n.t('notify.characteristicCertificateTypeCharacteristicUpdate'))
        return response.data
      }
      context.commit('SET_DATA', { store, state: 'isLoadCharacteristic', data: false })
      return false
    },
    async getNoiseInspection (context, id) {
      const response = await api.get(`${ endpoint.noiseInspection(id)}`)

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificateCharacteristicNoiseInspection', data: response.data })
        console.log(context)
        return response.data
      } return false
    },
    async addNoiseInspection (context, data) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })

      const response = await api.post( endpoint.noiseInspection(data.id), {body: data.body})

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'isLoad', data: false })
        return response.data
      }

      return response
    },
    async addEmissionInspection (context, data) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })

      const response = await api.post( endpoint.emissionInspection(data.id), {body: data.body})

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'isLoad', data: false })
        return response.data
      }

      return false
    },
    async getEmissionInspection (context, id) {
      const response = await api.get(`${ endpoint.emissionInspection(id)}`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificateCharacteristicEmissionInspection', data: response.data })
        return response.data
      } return false
    },

    async getVehicleConsumption (context, id) {
      const response = await api.get(`${ endpoint.vehicleConsumption(id)}`)

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificateCharacteristicVehicleConsumption', data: response.data })
        return response.data
      } return false
    },

    async addVehicleConsumption (context, data) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })

      const response = await api.post( endpoint.vehicleConsumption(data.id), {body: data.body})

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'isLoad', data: false })
        return response.data
      }
      return response
    },

    async createManualCertificate (context, data) {
      const response = await api.post(endpoint.manualCertificate(), { body: data.body })

      if (await requestValidation) {
        context.commit('SET_DATA', { store, state: 'manualCertificate', data: response.data })
        return response.data
      } return false
    }
  },
  getters: {
    characteristicDependency: state => fieldName => state.certificateTypeCharacteristicDependency.find(item => item.field_name === fieldName),
  }
}
