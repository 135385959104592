<template lang="pug">
  v-app
    Drawer(
      v-if="isShowNavigation"
      :drawer="drawer"
      :sidebarColor="sidebarColor"
      :sidebarTheme="sidebarTheme"
    )
    v-main(:style="!isShowNavigation ? { paddingLeft: '0 !important' } : ''")

      div(
        @click="drawer = false"
        v-if="drawer").position-absolute.drawer-state
      app-bar(
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
        :navbarFixed="navbarFixed"
        @toggleSettingsDrawer="toggleSettingsDrawer")

      fade-transition( :duration="200" origin="center top" mode="out-in")
        router-view

</template>
<script>
import { FadeTransition } from 'vue2-transitions'
import Drawer from '@/components/project/Drawer.vue'
import AppBar from '@/components/project/AppBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MainLayout',
  components: {
    FadeTransition,
    Drawer,
    AppBar
  },
  data () {
    return {
      drawer: null,
      sidebarColor: "primary",
      sidebarTheme: "transparent",
      navbarFixed: false,
    }
  },
  computed: {
    ...mapGetters(['isShowNavigation'])
  }
}
</script>