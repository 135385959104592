import Vue from 'vue'
import VueRouter from 'vue-router'
import { access } from '@/configs/permissions'
import store from '@/store'

Vue.use(VueRouter)

import certificationAgency from '@/router/certificationAgency.routes'
import measuringLaboratory from '@/router/measuringLaboratory.routes'
import registryUser from '@/router/registryUser'

import Main from '@/views/Main'
import Profile from '@/views/Profile/Profile'
import SignIn from '@/views/SignIn'
import NotFound from '@/views/NotFound.vue'

const Dashboard = () => import('../views/Dashboard.vue')

let defaultRoutes = [
  {
    path: '*',
    name: 'all',
    redirect: '/404'
  },
  {
    path: '/404',
    name: 'no-found',
    component: NotFound,
    meta: {
      public: true,
      access: 'public'
    },
  },
  {
    path: '/server-error',
    name: 'server-error',
    meta: {
      public: true
    },
  },
  {
    path: '/login',
    name: 'Authentication',
    component: SignIn,
    meta: {
      public: true
    },
  }
]

let certifications = [
  {
    path: '/certifications',
    name: 'allCertifications',
    meta: {
      groupName: 'menu.certifications',
      access: 'public'
    },
    component: () => import('@/views/Certification/Table')
  },
  {
    path: '/certifications/create',
    name: 'createCertification',
    meta: {
      groupName: 'menu.certifications',
      access: 'public'
    },
    component: () => import('@/views/Certification/form')
  },
  {
    path: '/certifications/:id',
    name: 'infoCertification',
    meta: {
      groupName: 'menu.certifications',
      access: 'public'
    },
    component: () => import('@/views/Certification/Info')
  },
  {
    path: '/certifications/manual/:id',
    name: 'infoCertificationManual',
    meta: {
      groupName: 'menu.certifications',
      access: 'public'
    },
    component: () => import('@/views/Certification/Manual')
  },
]

let certificationsTypeKtz = [
  {
    path: '/certifications-type-ktz',
    name: 'allCertificationsType',
    meta: {
      groupName: 'menu.certificationsType',
      access: 'public'
    },
    component: () => import('@/views/CertificationType/Table')
  },
  {
    path: '/certifications-type-ktz/create',
    name: 'createCertificationType',
    meta: {
      groupName: 'menu.certificationsType',
      access: 'public'
    },
    component: () => import('@/views/CertificationType/CreateCertificationType')
  },
  {
    path: '/certifications-type-ktz/:id',
    name: 'infoCertificationsTypeKtz',
    meta: {
      groupName: 'menu.certifications',
      access: 'public'
    },
    component: () => import('@/views/CertificationType/Info')
  },
  {
    path: '/add-description-ktz/:id',
    name: 'addDescription',
    meta: {
      groupName: 'menu.certifications',
      access: 'public'
    },
    component: () => import('@/views/CertificationType/CharacteristicForm')
  }
]

let profile = [
  {
    path: '/profile',
    name: 'user-profile',
    component: Profile,
    redirect: { name: 'profile-information' },
    meta: {
      access: 'public',
      groupName: 'menu.profile',
    },
    children: [
      {
        path: '/profile/information',
        name: 'profile-information',
        component: () => import('@/views/Profile/ProfileInformation.vue'),
        meta: {
          groupName: 'menu.profile',
        },
      },
      {
        path: '/profile/settings',
        name: 'profile-settings',
        component: () => import('@/views/Profile/ProfileSettings.vue'),
        meta: {
          groupName: 'menu.profile',
        },
      }
    ],
  }
]

const routes = [
  {
    path: '/',
    name: 'Main',
    redirect: '/dashboard',
    component: Main,
    children: [
      {
        path: 'dashboard',
        name: 'states',
        component: Dashboard,
        meta: {
          groupName: 'menu.dashboard',
        },
      },
      ...certifications,
      ...certificationsTypeKtz,
      ...certificationAgency,
      ...measuringLaboratory,
      ...registryUser,
      ...profile,
    ],
  },
  ...defaultRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  if (from.name === to.name && JSON.stringify(from.query) !== JSON.stringify(to.query)) {
    next()
  }
  else {
    const isAuth = Vue.prototype.$auth.isAuth()
    let profile = store.state.profile.profile
    store.commit('SET_LOAD', false)

    if (isAuth) {
      if (Object.keys(profile).length) {
        store.commit('SET_LOAD', true)
        if (to.name === 'no-found' || to.name === 'server-error') next()
        else if (to.meta.public) next('/')
        else access(to.meta.access) ? next() : next('/404')
      } else {
        store.dispatch('getProfile')
          .then(() => {
            if (!store.getters.isShowNavigation) {
              next('/profile/settings')
            } else {
              if (to.name === 'no-found' || to.name === 'server-error') next()
              else if (to.meta.public) next('/')
              else access(to.meta.access) ? next() : next('/404')
            }
          })
          .finally(() => {
            store.commit('SET_LOAD', true)
          })
      }
    } else {
      let oldPath = encodeURIComponent(window.location.pathname)
      store.commit('SET_LOAD', true),
        ['Authentication'].includes(to.name) ? next() : next('/login'+ '?path=' + oldPath)
    }
  }
})

export default router
