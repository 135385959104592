/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import '@mdi/font/css/materialdesignicons.css'
import "@fortawesome/fontawesome-free/css/all.css";

const vuetify = new Vuetify({
  theme: {
    // disable: true,
    themes: {
      light: {
        success: '#12B76A',
        danger: '#F04438',
        info: '#2F6FED',
        warning: '#FEF0C7',
        // secondary: '#202124'
      }
    }
  },
  icons: {
    iconfont: "fa" || 'mdi'
  },
});

Vue.use(Vuetify);

export default new Vuetify({
  rtl: false,
});
