<template lang="pug">
  v-app-bar(
    :color="background"
    height="auto"
    :class=" navbarFixed ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2': '' "
    flat).mt-5.px-0.mx-6.border-radius-xl.toolbar-content-padding-y-none
    v-row.py-1
      v-col(cols="12" sm="6").d-flex.align-center
        template(v-if="isShowNavigation")
          div
            AppBreadcrumbs
          div(
            :class="{ active: togglerActive }"
            @click="minifyDrawer"
            v-if="!$vuetify.breakpoint.mobile"
          ).drawer-toggler.pa-5.ms-6.cursor-pointer
            div.drawer-toggler-inner
              i.drawer-toggler-line.bg-body
              i.drawer-toggler-line.bg-body
              i.drawer-toggler-line.bg-body
        div.text-h4.font-weight-bold.py-3(v-else) {{ $t('projectName') }}

      v-col(
        cols="12"
        sm="6"
        :class="$vuetify.rtl ? 'text-sm-left' : 'text-sm-right'"
      ).d-flex.align-center.justify-end
        v-btn(
          text
          to="/profile"
        ).font-weight-600.text-body.ls-0.text-capitalize
          v-icon(size="22").mr-2 mdi-account-circle-outline
          span {{ $t('btn.profile') }}

        v-btn(
          text
          @click="logoutApp"
        ).font-weight-600.text-body.ls-0.text-capitalize.ml-10
          v-icon(size="22").mr-2 mdi-logout
          span {{ $t('btn.logout') }}

        v-btn(
          elevation="0"
          :ripple="false"
          height="43"
          :class="{ 'btn-dark-toggler-hover': !hasBg, 'btn-toggler-hover': hasBg, active: togglerActive, }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose").font-weight-600.text-capitalize.drawer-toggler.py-3.px-0.rounded-sm

          div.drawer-toggler-inner
            i.drawer-toggler-line.text-body
            i.drawer-toggler-line.text-body
            i.drawer-toggler-line.text-body
</template>
<script>
import AppBreadcrumbs from '@/components/project/AppBreadcrumbs'
import { logout } from '@ananasbear/auth'
import { mapGetters } from 'vuex'

export default {
  name: "app-bar",
  components: {
    AppBreadcrumbs
  },
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: String,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      logout,
      dropdown: [
        {
          avatar: require("@/assets/img/team-2.jpg"),
          title:
            '<span class="font-weight-bold">New message</span> from Laur</h6>',
          time: "13 minutes ago",
        },
        {
          image: require("@/assets/img/small-logos/logo-spotify.svg"),
          title:
            '<span class="font-weight-bold">New album</span> by Travis Scott',
          time: "1 day",
        },
        {
          icon: require("@/assets/img/icons/card.svg"),
          title: "Payment successfully completed",
          time: "2 days",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['isShowNavigation'])
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },

    logoutApp () {
      this.logout()
      window.$chatwoot.reset()
      location = '/login'
    }
  },
};
</script>
