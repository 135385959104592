import { i18n } from '@/plugins/vue-i18n.js'

export const mediaType = {
  video: ['mp4', 'webm'],
  image: ['png', 'jpeg', 'jpg'],
  pdf: ['pdf'],
  document: ['doc', 'docx']
}

export const moderationStatusesOC = [
  { id: null, name: i18n.t('statuses.allStatuses') },
  { id: 3, name: i18n.t('statuses.oc.moderationTechSupportReview') },
  { id: 6, name: i18n.t('statuses.oc.moderationNaauReview') },
  { id: 4, name: i18n.t('statuses.oc.moderationStatementApproved') },
  { id: 7, name: i18n.t('statuses.oc.moderationMiuReview') },
  { id: 5, name: i18n.t('statuses.oc.moderationRejected') },
  { id: 10, name: i18n.t('statuses.oc.moderationReprocessing') }
]

export const moderationStatusesVL = [
  { id: null, name: i18n.t('statuses.allStatuses') },
  { id: 2, name: i18n.t('statuses.vl.moderationVLWaitTechSupportReview') },
  { id: 3, name: i18n.t('statuses.vl.moderationVLWaitNaauReview') },
  { id: 4, name: i18n.t('statuses.vl.moderationVLStatementApproved') },
  { id: 6, name: i18n.t('statuses.vl.moderationVLRejected') },
  { id: 10, name: i18n.t('statuses.vl.moderationVLSentForReprocessing') }
]

export const questionGroupOC = {
  infoOC: {
    1: ['name', 'short_name', 'address', 'EDPROU', 'code', 'phone_numbers', 'landline_phone', 'web_resource_link', 'email', 'order_number', 'order_date',
      'accreditation_certificate_number', 'accreditation_certificate_date_start', 'accreditation_certificate_date_end',
      'allow_categories'],
    2: ['insurance_fields'],
    3: ['author', 'director_passport_serial', 'director_passport_inn'],
    4: ['passport', 'itn'],
    5: ['accreditationCertificateVersionNAAU', 'accreditationCertificateNAAU', 'insurance', 'orderMIU', 'spheresAccreditationProject', 'appointmentOrderOfManager', 'extractFromTheRegisterEDRPOU']
  },
  employeeOC: {
    1: ['first_name', 'last_name', 'middle_name', 'serial', 'inn', 'position'],
    2: ['education_document_number', 'educational_institution', 'education_document_issued_date', 'specialization'],
    3: ['direction_raising_qualification', 'raising_qualification_document_number', 'raising_qualification_date', 'experience_years'],
    4: ['educationDocument', 'raisingQualificationDocument'],
    5: ['identityDocument', 'itn']
  },
  branchOC: {
    1: ['head_full_name', 'address', 'phone_number', 'email']
  }
}

export const questionGroupVL = {
  infoVL: {
    1: ['name', 'short_name', 'address', 'EDPROU', 'phone_numbers', 'landline_phone', 'web_resource_link', 'email', 'accreditation_certificate_number', 'accreditation_certificate_date_end'],
    2: ['author', 'director_passport_serial', 'director_passport_inn'],
    3: ['passport', 'itn'],
    4: ['extractFromTheRegisterEDRPOU', 'sphereAccreditation', 'legalDocumentsVL', 'accreditationCertificateNAAU', 'appointmentOrderOfManager', 'passportVL']
  },
  branchVL: {
    1: ['name', 'address', 'number', 'allow_categories'],
    2: ['sphereAccreditation', 'passportVL', 'dataMeasuringMethods']
  }
}

export const certificateStatusesDashboard = [
  { id: 1, name: i18n.t('statuses.certificate.dashboard.active') },
  { id: 2, name: i18n.t('statuses.certificate.dashboard.cancelled') },
  { id: 3, name: i18n.t('statuses.certificate.dashboard.recalled') },
  { id: 4, name: i18n.t('statuses.certificate.dashboard.dubbed') }
]
export const certificateTypeDashboard = [
  { id: 'certificate_type', name: i18n.t('dashboard.chart.certificate_type'), title: i18n.t('dashboard.chartTitle.certificate_type') },
  { id: 'certificates', name: i18n.t('dashboard.chart.certificates'), title: i18n.t('dashboard.chartTitle.certificates') },
  { id: 'total_certificate_type', name: i18n.t('dashboard.chart.total_certificate_type'), title: i18n.t('dashboard.chartTitle.total_certificate_type') },
  { id: 'total_certificates', name: i18n.t('dashboard.chart.total_certificates'), title: i18n.t('dashboard.chartTitle.total_certificates') },
  { id: 'total_vehicle_certificates', name: i18n.t('dashboard.chart.total_vehicle_certificates'), title: i18n.t('dashboard.chartTitle.total_vehicle_certificates') }
]

export const dateTypeItem = [
  { id: 1, name: 'За Проміжок', value: ['created_at_gt', 'created_at_lt'] },
  { id: 2, name: 'За Рік', value: ['created_at_year'] },
  { id: 3, name: 'За Місяць', value: ['created_at_month'] },
  { id: 4, name: 'За День', value: ['created_at'] }
]

export const activitySelectArray = [
  { id: true, name: 'Активний' },
  { id: false, name: 'Неактивний' },
]

export const certificateTypeStatuses = [
  { id: 1, name: i18n.t('statuses.certificateTypeKtz.certificateTypeKtzApproved') },
  { id: 2, name: i18n.t('statuses.certificateTypeKtz.certificateTypeKtzRejected') },
  { id: 3, name: i18n.t('statuses.certificateTypeKtz.certificateTypeKtzCanceled') },
  { id: 4, name: i18n.t('statuses.certificateTypeKtz.certificateTypeKtzProcessing') }
]
