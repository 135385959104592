export default {
  statuses: {
    certificateWaitForDecision: 'Очікує рішення щодо видачі',
    certificateWaitSignatureDirector: 'Очікує підписання',
    certificateValid: 'Дійсний',
    active: 'Активний',
    notActive: 'Неактивний',
    allStatuses: 'Всі статуси',
    waitForSetData: 'Очікує введення даних',
    certificateTypeKtz: {
      certificateTypeKtzApproved: 'Затверджено',
      certificateTypeKtzRejected: 'У затвердженні типу відмовлено',
      certificateTypeKtzCanceled: 'Затвердження типу відкликано',
      certificateTypeKtzProcessing: 'В обробці'
    },
    oc: {
      moderationTechSupportReview: 'Перевірка техпідримки',
      moderationNaauReview: 'Перевірка НААУ',
      moderationStatementApproved: 'Активна',
      moderationMiuReview: 'Перевірка МІУ',
      moderationRejected: 'Відхилено',
      moderationTechReprocessing: 'На доопрацюванні',
      moderationReprocessing: 'На доопрацюванні'
    },
    vl: {
      moderationVLWaitTechSupportReview: 'Перевірка техпідримки',
      moderationVLWaitNaauReview: 'Перевірка НААУ',
      moderationVLWaitMiuReview: 'Перевірка МІУ',
      moderationVLStatementApproved: 'Активна',
      moderationVLRejected: 'Відхилено',
      moderationVLTechSentForReprocessing: 'На доопрацюванні',
      moderationVLSentForReprocessing: 'На доопрацюванні'
    },
    moderationBranch: {
      statementBranchWaitTechSupportReview: 'Перевірка техпідримки',
      statementBranchWaitNaauReview: 'Перевірка НААУ',
      statementBranchApproved: 'Активна',
      statementBranchRejected: 'Відхилено',
      statementBranchTechSentForReprocessing: 'На доопрацюванні',
      statementBranchSentForReprocessing: 'На доопрацюванні',
      statementBranchModeration: 'Модерація дільниці'
    },
    certificate: {
      dashboard: {
        active: 'Дійсні',
        cancelled: 'Анульовані',
        recalled: 'Відкликані',
        dubbed: 'Дубльований'
      }
    }
  },
}