import Vue from 'vue'

const SET_DATA = (context, data) => {
  context[data.store][data.state] = data.data
}

const ADD_DATA = (context, data) => {
  context[data.store][data.state].push(data.data)
}

const UPDATE_DATA = (context, data) => {
  let index = context[data.store][data.state].findIndex(e => e.id === data.data.id)
  if (index !== -1) {
    context[data.store][data.state].splice(index, 1, data.data)
  }
}

const DELETE_DATA = (context, data) => {
  context[data.store][data.state] = context[data.store][data.state].filter(i => i.id !== data.data)
}

const DELETE_DATA_TOKEN = (context, data) => {
  console.log(context[data.store][data.state].results.filter(i => i.pk_md5 !== data.data), 'data')
  context[data.store][data.state].results = context[data.store][data.state].results.filter(i => i.pk_md5 !== data.data)
}

const ADD_PASSPORT_TO_CLIENTS = (context, data) => {
  let client = context[data.store][data.state].find(i => i.id === data.data.id)

  Vue.set(client, 'passport', data.data.passport)
}

const CLEAR_INSPECTION = context => {
  context.inspection.inspection = {}
  context.inspection.dependency = {}
  context.inspection.lightTransmissionMeasurement = {}
  context.inspection.lightMeasurement = {}
  context.inspection.brakeMeasurement = {}
  context.inspection.gasMeasurement = {}
  context.inspection.smokeMeasurement = {}
  context.inspection.noiseMeasurement = {}
  context.inspection.questions = []
  context.inspection.vehicle = []
  context.inspection.proofs = []
}

const SET_QUESTION_BY_GROUP = (state, payload) => {
  let { groupConfig, data } = payload
  let questionArray = []
  for (let group in groupConfig) {
    groupConfig[group].forEach(fieldName => {
      let question = data.find(question => question.check_list_question.field_name === fieldName)
      if (question) {
        question.group = parseInt(group)
        question.is_file_question = false
        questionArray.push(question)
      }
    })
  }
  state[payload.store][payload.state] = questionArray
}

const SET_QUESTION_FILE_BY_GROUP = (state, payload) => {
  let { groupConfig, data } = payload
  let questionArray = []
  for (let group in groupConfig) {
    groupConfig[group].forEach(fieldName => {
      let question = data.filter(question => question.file_type === fieldName)
      if (question.length) {
        for(let i = 0; i < question.length; i++) {
          question[i].group = parseInt(group)
          question[i].is_file_question = true
          question[i].check_list_question = { field_name: question[i].file_type }
          question[i].field_value = question[i].file_url
          delete question[i].file_url
          delete question[i].file_type
          questionArray.push(question[i])
        }
      }
    })
  }
  state[payload.store][payload.state] = state[payload.store][payload.state].concat(questionArray)
}

const SET_QUESTION_DECISION = (state, payload) => {
  let index = state[payload.store][payload.state].findIndex(e => e.external_id === payload.data.external_id)
  if (index !== -1) {
    let question = {...state[payload.store][payload.state][index], ...payload.data}
    state[payload.store][payload.state].splice(index, 1, question)
  }
}

export default {
  SET_DATA, DELETE_DATA, ADD_DATA, UPDATE_DATA, ADD_PASSPORT_TO_CLIENTS, DELETE_DATA_TOKEN, CLEAR_INSPECTION, SET_QUESTION_BY_GROUP,
  SET_QUESTION_FILE_BY_GROUP, SET_QUESTION_DECISION
}
