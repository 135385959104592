import api from '@/plugins/api'
import { statistic as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
const store = 'dashboard'

export default {
  state: () => ({
    statistic: {},
    chartData: {},
    isLoading: false
  }),
  actions: {
    async getStatistic(context) {
      const response = await api.get(endpoint.generalStatistic)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'statistic', data: response.data })
      }
    },
    async getChart(context, filter) {
      context.commit('SET_DATA', { store, state: 'isLoading', data: true })
      const params = new URLSearchParams()
      for (let field in filter) {
        filter[field] && params.append(field, filter[field])
      }
      const response = await api.get(`${ endpoint.generalChart }?${ params }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'chartData', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoading', data: false })
    }
  },
  getters: {
    statistic: state => {
      return state.statistic = [
        {
          type: 'certificates',
          title: 'dashboard.statistic.certificatesTitle',
          value: state.statistic.certificates_count,
          valueChange: state.statistic.certificates_created_today_count,
          valueChangeMinus: null
        },
        {
          type: 'certificates_type',
          title: 'dashboard.statistic.certificatesTypeTitle',
          value: state.statistic.certificates_type_count,
          valueChange: state.statistic.certificates_type_created_today_count,
          valueChangeMinus: null
        },
        {
          type: 'certification_agency',
          title: 'dashboard.statistic.certificationAgencyTitle',
          value: state.statistic.certification_agency_count,
          valueChange: state.statistic.certification_agency_created_today_count,
          valueChangeMinus: state.statistic.certification_agency_deactivated_today_count,
        },
      ]
    },

    chartData: state => {
      Object.keys(state.chartData).forEach(item => {
        state.chartData[item] = state.chartData[item].map(e => {
          return {
            ...e,
            date: e.date * 1000
          }
        })
      })
      return state.chartData
    }
  }
}
