<template lang="pug">
  div.d-flex.align-center.justify-center.flex-column(:style="{ 'min-height': `${height}px` }")
    img(src="@/assets/icons/pic_nothing_found.svg")
    div.subtitle-1.mt-5 {{ $t('nothingFound') }}
</template>

<script>

export default {
  name: 'NothingFound',
  props: {
    height: { type: [Number, String], default: 500 }
  }
}
</script>