// статусы модерации ОС
const moderationTechSupportReview = 3
const moderationNaauReview = 6
const moderationStatementApproved = 4
const moderationMiuReview = 7
const moderationRejected = 5
const moderationTechReprocessing = 10
const moderationReprocessing = 11

// статусы модерации ВЛ
const moderationVLWaitTechSupportReview = 2
const moderationVLWaitNaauReview = 3
const moderationVLStatementApproved = 4
const moderationVLRejected = 6
const moderationVLTechSentForReprocessing = 10
const moderationVLSentForReprocessing = 11

// statement new branch VL
const statementBranchWaitTechSupportReview = 1 // Очікує перевірки технічної підримки
const statementBranchWaitNaauReview = 2 // Очікує перевірки НААУ
const statementBranchApproved = 3 // Філія по заяві створена
const statementBranchRejected = 4 // Заява на створення філії відхилена
const statementBranchTechSentForReprocessing = 10 // Заява відправлена на переробку, тех підримки
const statementBranchSentForReprocessing = 11 // Заява відправлена на переробку

// статуси сертифіката
const certificateWaitForDecision = 3 // Очікує рішення щодо можливості видачі сертифіката (тимчасовий статус для сертифіката)
const certificateWaitSignatureDirector = 2 // Очікує підписання
const certificateValid = 1 // Дійсний

// статуси сертифіката доданих вручну
const manualCertificateWaitData = 0
const manualCertificateValid = 1
const manualCertificateDublicated = 2

// статуси сертифікати типу КТЗ
const certificateTypeKtzApproved = 1 // Затверджено
const certificateTypeKtzRejected = 2 // У затвердженні типу відмовлено
const certificateTypeKtzCanceled = 3 // Затвердження типу відкликано
const certificateTypeKtzProcessing = 4 // В обробці

const active = true // Активний
const notActive = false // Неактивний

const activity = value => {
  switch (value) {
    case active:
      return { color: 'success', textColor: 'info--text', text: 'statuses.active' }
    case notActive:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.notActive' }
    default: return { color: 'bg-gradient-primary', textColor: 'white', text: 'statuses.default' }
  }
}

const moderationOC = id => {
  switch (id) {
    case moderationTechSupportReview:
      return { color: 'warning', textColor: '#F79009', text: 'statuses.oc.moderationTechSupportReview' }
    case moderationTechReprocessing:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.oc.moderationTechReprocessing' }
    case moderationNaauReview:
      return { color: 'warning', textColor: '#F79009', text: 'statuses.oc.moderationNaauReview' }
    case moderationStatementApproved:
      return { color: 'success', textColor: 'info--text', text: 'statuses.oc.moderationStatementApproved' }
    case moderationMiuReview:
      return { color: 'warning', textColor: '#F79009', text: 'statuses.oc.moderationMiuReview' }
    case moderationRejected:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.oc.moderationRejected' }
    case moderationReprocessing:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.oc.moderationReprocessing' }
  }
}

const moderationVL = id => {
  switch (id) {
    case moderationVLWaitTechSupportReview:
      return { color: 'warning', textColor: '#F79009', text: 'statuses.vl.moderationVLWaitTechSupportReview' }
    case moderationVLTechSentForReprocessing:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.vl.moderationVLTechSentForReprocessing' }
    case moderationVLWaitNaauReview:
      return { color: 'warning', textColor: '#F79009', text: 'statuses.vl.moderationVLWaitNaauReview' }
    case moderationVLStatementApproved:
      return { color: 'success', textColor: '#FFFFFF', text: 'statuses.vl.moderationVLStatementApproved' }
    case moderationVLRejected:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.vl.moderationVLRejected' }
    case moderationVLSentForReprocessing:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.vl.moderationVLSentForReprocessing' }
  }
}

const statementBranch = id => {
  switch (id) {
    case statementBranchWaitTechSupportReview:
      return { color: 'warning', textColor: '#F79009', text: 'statuses.moderationBranch.statementBranchWaitTechSupportReview' }
    case statementBranchWaitNaauReview:
      return { color: 'warning', textColor: '#F79009', text: 'statuses.moderationBranch.statementBranchWaitNaauReview' }
    case statementBranchApproved:
      return { color: 'success', textColor: '#FFFFFF', text: 'statuses.moderationBranch.statementBranchApproved' }
    case statementBranchRejected:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.moderationBranch.statementBranchRejected' }
    case statementBranchTechSentForReprocessing:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.moderationBranch.statementBranchTechSentForReprocessing' }
    case statementBranchSentForReprocessing:
      return { color: 'danger', textColor: '#FFFFFF', text: 'statuses.moderationBranch.statementBranchSentForReprocessing' }

    default: return { color: '', textColor: '', text: 'statuses.default'}
  }
}

const certificate = id => {
  switch (id) {
    case certificateWaitForDecision: // тимчасовий статус для сертифіката
      return { color: 'orange lighten-1', textColor: 'white', text: 'statuses.certificateWaitForDecision' }
    case certificateWaitSignatureDirector:
      return { color: 'info lighten-1', textColor: 'white', text: 'statuses.certificateWaitSignatureDirector' }
    case certificateValid:
      return { color: 'success lighten-1', textColor: 'white', text: 'statuses.certificateValid' }
    default: return { color: 'bg-gradient-primary', textColor: 'white', text: 'statuses.default' }
  }
}

const manualCertificate = id => {
  switch (id) {
    case manualCertificateWaitData: // тимчасовий статус для сертифіката
      return { color: 'info lighten-1', textColor: 'white', text: 'statuses.waitForSetData' }
    case manualCertificateValid: // тимчасовий статус для сертифіката
      return { color: 'success lighten-1', textColor: 'white', text: 'statuses.certificateValid' }
    case manualCertificateDublicated: // тимчасовий статус для сертифіката
      return { color: 'orange lighten-1', textColor: 'white', text: 'statuses.certificate.dashboard.dubbed' }
    default: return { color: 'bg-gradient-primary', textColor: 'white', text: 'statuses.default' }
  }
}

const certificateTypeKtz = id => {
  switch (id) {
    case certificateTypeKtzApproved:
      return { color: 'success lighten-1', textColor: 'white', text: 'statuses.certificateTypeKtz.certificateTypeKtzApproved' }
    case certificateTypeKtzRejected:
      return { color: 'error lighten-1', textColor: 'white', text: 'statuses.certificateTypeKtz.certificateTypeKtzRejected' }
    case certificateTypeKtzCanceled:
      return { color: 'orange lighten-1', textColor: 'white', text: 'statuses.certificateTypeKtz.certificateTypeKtzCanceled' }
    case certificateTypeKtzProcessing:
      return { color: 'info lighten-1', textColor: 'white', text: 'statuses.certificateTypeKtz.certificateTypeKtzProcessing' }
    default: return { color: 'bg-gradient-primary', textColor: 'white', text: 'statuses.default' }
  }
}


export default {
  certificate,
  manualCertificate,
  moderationOC,
  moderationVL,
  statementBranch,
  certificateTypeKtz,
  activity
}
export const list = {
  certificateWaitSignatureDirector,
  certificateValid,

  moderationTechSupportReview,
  moderationNaauReview,
  moderationStatementApproved,
  moderationMiuReview,
  moderationRejected,
  moderationReprocessing,

  statementBranchWaitTechSupportReview,
  statementBranchWaitNaauReview,
  statementBranchApproved,
  statementBranchRejected,
  statementBranchTechSentForReprocessing,
  statementBranchSentForReprocessing
}
