<template lang="pug">
  v-navigation-drawer(
    width="100%"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
    :right="$vuetify.rtl"
    :class="!$vuetify.breakpoint.mobile ? '' : 'bg-white'"
    :data-color="sidebarColor"
    :data-theme="sidebarTheme").my-4.ms-4.border-radius-lg

    v-list-item.pa-0
      v-list-item-content.pa-0
        v-list-item-title.title.d-flex.align-center.mb-0
          div.v-navigation-drawer-brand.d-flex.align-center.pt-5
            v-img(src="@/assets/img/illustrations/rocket-white.png" width="80").navbar-brand-img.ms-3
            //v-img(src="@/assets/img/logo-ct.png" width="35").navbar-brand-img.ms-3
            span.ms-2.font-weight-bold.text-sm CRM Certification

    v-list(nav dense)
      hr.horizontal.dark.my-4

      h5.text-uppercase.text-caption.ls-0.font-weight-bolder
      .p-0.text-muted.mx-4.mt-4.mb-2.ps-2.d-none-mini.white-space-nowrap {{ $t('menu.menu') }}

      v-list-item-group
        template(v-for="(item, i) in menuItems")
          v-list-item(
            :key="i"
            v-if="access(item.access)"
            link
            :to="item.link"
            :ripple="false"
            active-class="item-active"
            ).pb-1.mx-2.no-default-hover
            v-list-item-icon.shadow.border-radius-md.mx-2.align-center.justify-center.pa-0
              img.image(:src="$route.path.includes(item.link) ? item.actionActive : item.action")
            v-list-item-title.ms-1 {{ $t(item.title) }}
</template>
<script>
import Rocket from '@/assets/img/icons/rocket-dark.svg'
import RocketActive from '@/assets/img/icons/rocket.svg'
import Document from '@/assets/img/icons/document-dark.svg'
import DocumentActive from '@/assets/img/icons/document.svg'
import OC from '@/assets/img/icons/oc.svg'
import OCActive from '@/assets/img/icons/oc-dark.svg'
import VL from '@/assets/img/icons/vl.svg'
import VLActive from '@/assets/img/icons/vl-dark.svg'
import RegistryUser from '@/assets/img/icons/account-supervisor-dark.svg'
import RegistryUserActive from '@/assets/img/icons/account-supervisor.svg'
import { access } from '@/configs/permissions'

export default {
  name: 'DrawerMenu',
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
    sidebarColor: {
      type: String,
      default: "success",
    },
    sidebarTheme: {
      type: String,
      default: "transparent",
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    access,
    menuItems: [
      {
        action: Rocket,
        actionActive: RocketActive,
        link: '/dashboard',
        title: 'menu.dashboard'
      },
      {
        action: Document,
        actionActive: DocumentActive,
        link: '/certifications/',
        title: 'menu.certifications'
      },
      {
        action: Document,
        actionActive: DocumentActive,
        link: '/certifications-type-ktz/',
        title: 'menu.certificationsType'
      },
      {
        action: OC,
        actionActive: OCActive,
        link: '/certification-agency/',
        title: 'menu.certificationAgency'
      },
      {
        action: VL,
        actionActive: VLActive,
        link: '/measuring-laboratory/',
        title: 'menu.measuringLaboratory',
        access: 'viewPageVL'
      },
      {
        action: RegistryUser,
        actionActive: RegistryUserActive,
        link: '/user/',
        title: 'menu.registryUser',
        access: 'viewPageRegistryUser'
      }
    ]
  }),
};
</script>
