var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.filteredHeaders,"items":_vm.items,"search":_vm.search,"page":_vm.page,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"mobile-breakpoint":"0","loading-text":"Завантаження...","no-results-text":"Дані не знайдено","no-data-text":"Даних немає","loading":_vm.isLoad,"must-sort":_vm.hasSorting,"server-items-length":_vm.totalCountItem,"options":_vm.options},on:{"update:page":function($event){_vm.page=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('NothingFound',{attrs:{"height":"300"}})]},proxy:true},{key:"item.addressString",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.addressString(item.address)))])]}},{key:"item.position",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.userprofile.position))])]}},{key:"item.organization",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.userprofile.organization_name))])]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.fullName(item, true)))])]}},{key:"item.accreditation_certificate_date_end",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.accreditation_certificate_date_end).format("DD.MM.YYYY")))])]}},{key:"item.date_end",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.date_end).format("DD.MM.YYYY")))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$moment(item.created_at).format("DD.MM.YYYY")))])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"align-self-start border-radius-xs font-weight-bold",attrs:{"color":_vm.statuses[_vm.typeStatus](item.is_active).color,"text-color":_vm.statuses[_vm.typeStatus](item.statement_step).textColor,"label":"","small":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"width":"10px","height":"10px"},attrs:{"size":"30"}},[_vm._v("mdi-circle-small")]),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.statuses[_vm.typeStatus](item.is_active).text)))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"align-self-start border-radius-xs font-weight-bold",attrs:{"color":_vm.statuses[_vm.typeStatus](item.status_document).color,"text-color":_vm.statuses[_vm.typeStatus](item.statement_step).textColor,"label":"","small":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"width":"10px","height":"10px"},attrs:{"size":"30"}},[_vm._v("mdi-circle-small")]),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.statuses[_vm.typeStatus](item.status_document).text)))])],1)]}},{key:"item.statement_step",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.statement_step !== -1)?_c('v-chip',{staticClass:"align-self-start border-radius-xs font-weight-bold",attrs:{"color":_vm.statusObject(item.statement_step).color,"text-color":_vm.statusObject(item.statement_step).textColor,"label":"","small":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"width":"10px","height":"10px"},attrs:{"size":"30"}},[_vm._v("mdi-circle-small")]),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.statusObject(item.statement_step).text)))])],1):_vm._e(),(item.has_moderation_branches)?_c('v-chip',{staticClass:"align-self-start border-radius-xs font-weight-bold ml-4",attrs:{"color":_vm.statusModerationNewBranch.color,"text-color":_vm.statusModerationNewBranch.textColor,"label":"","small":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"width":"10px","height":"10px"},attrs:{"size":"30"}},[_vm._v("mdi-circle-small")]),_c('span',[_vm._v(_vm._s(_vm.$t(_vm.statusModerationNewBranch.text)))])],1):_vm._e()],1)]}},(_vm.isShowDetailedButton)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"btn-ls me-2 my-2 rounded-sm",attrs:{"icon":"","elevation":"0","ripple":false,"height":"28","min-width":"36","width":"36","color":"#67748e"},on:{"click":function($event){return _vm.$router.push(("" + (_vm.detailedRoute + item.id)))}}},[_c('v-icon',{staticClass:"text-black",attrs:{"size":"18"}},[_vm._v("fas fa-info")])],1)]}}:null],null,true)})],1),_c('v-card-actions',{staticClass:"card-padding"},[(!!_vm.items.length)?_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"6"}},[(_vm.count > 1)?_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#cb0c9f","length":_vm.count,"circle":""},on:{"input":_vm.change},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }