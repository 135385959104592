import { profile } from '@/configs/endpoints'
import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { initPermissions } from '@/configs/permissions'
import router from '@/router'

const store = 'profile'

export default {
  state: () => ({
    profile: {},
    sessions: {},
    signatureKeys: [],
    isLoadSessions: false,
    isLoadSignatureKeys: false,
  }),
  actions: {
    async signIn (context, body) {
      const response = await api.post(profile.signIn, {body: body})
      if (await requestValidation(response)) await context.dispatch('setAuth', response)
    },

    async signQR (context, code) {
      const response = await api.get(profile.getTokenByQR + code)
      if (await requestValidation(response)) await context.dispatch('setAuth', response)
    },

    async getProfile (context) {
      const response = await api.get(profile.me)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'profile', data: response.data })

        initPermissions()

        window.chatwootSDK?.run({
          websiteToken: process.env.VUE_APP_CHATWOOT_TOKEN,
          baseUrl: process.env.VUE_APP_CHATWOOT_BASE_URL
        })

        let {id, email, first_name, last_name, middle_name, type_user} = response.data

        let contact = {
          email: email,
          name: `${last_name} ${first_name} ${middle_name}`,
          company_name: type_user
        }

        window.addEventListener('chatwoot:ready', () => {
          window.$chatwoot.setUser(id, contact)
        })

      }
    },
    async updateProfile (context, data) {
      const response = await api.patch(profile.me, { body: data })
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'profile', data: response.data })
        return response
      }
    },
    async changePassword (context, body) {
      const response = await api.post(profile.changePassword, { body: body })
      return response.data
    },
    setShouldChangePassword (context) {
      context.commit('SET_DATA', { store, state: 'profile', data: {
        ...context.state.profile.profile,
        should_change_password: false
      } })

      router.push({ name: 'Main' })

    }
  },
  getters: {
    isShowNavigation: state => !state.profile.should_change_password
  }
}
