<template lang="pug">
v-breadcrumbs.pb-0.pt-1.px-0
  v-breadcrumbs-item(to="/").opacity-5.home-link
    //active-class="active-breadcrumb"
    v-icon.mr-1 mdi-home
    template {{ $t('home') }}
  li.v-breadcrumbs__divider.opacity-5.px-2.text-muted /

  v-breadcrumbs-item(v-if="isShowBreadCrumb").opacity-5.text-dark
    template {{ $t($route.meta.groupName) }}
  li(v-if="isShowBreadCrumb").v-breadcrumbs__divider.opacity-5.px-2.text-muted /

  v-breadcrumbs-item(active-class="active-breadcrumb").no-default-hover.text-dark
    template {{ $t($route.name) }}
</template>

<script>
export default {
  name: 'AppBreadcrumbs',
  computed: {
    isShowBreadCrumb() {
      return !['states'].includes(this.$route.name)
    }
  }
}
</script>

<style>
.home-link a {
  color: #344767 !important;
}
</style>
