export default [
  {
    path: '/certification-agency',
    name: 'certificationAgencyRegisteredList',
    meta: {
      groupName: 'menu.certificationAgency'
    },
    redirect: { name: 'oc-registered' },
    component: () => import('@/views/CertificationAgency/Table'),
    children: [
      {
        path: 'statement',
        name: 'oc-statement',
        meta: {
          groupName: 'menu.certificationAgency'
        }
      },
      {
        path: 'registered',
        name: 'oc-registered',
        meta: {
          groupName: 'menu.certificationAgency'
        }
      }
    ]
  },
  {
    path: '/certification-agency/statement/:id',
    name: 'certificationAgencyStatement',
    redirect: { name: 'oc-statement-information' },
    component: () => import('@/views/CertificationAgency/Moderation/Index.vue'),
    children: [
      {
        path: 'information',
        name: 'oc-statement-information',
        meta: {
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Moderation/Information.vue')
      },
      {
        path: 'employee',
        name: 'oc-statement-employee-list',
        meta: {
          access: 'viewEmployeeTab',
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Moderation/EmployeeList.vue')
      },
      {
        path: 'employee/:employee_id',
        name: 'oc-statement-employee-item',
        meta: {
          access: 'viewEmployeeTab',
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Moderation/Employee.vue')
      },
      {
        path: 'branch',
        name: 'oc-statement-branch-list',
        meta: {
          access: 'viewBranchTab',
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Moderation/BranchList.vue')
      },
      {
        path: 'branch/:branch_id',
        name: 'oc-statement-branch-item',
        meta: {
          access: 'viewBranchTab',
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Moderation/Branch.vue')
      }
    ]
  },

  {
    path: '/certification-agency/registered/:id',
    name: 'certificationAgencyRegistered',
    redirect: { name: 'oc-registered-information' },
    component: () => import('@/views/CertificationAgency/Registered/Index.vue'),
    children: [
      {
        path: 'information',
        name: 'oc-registered-information',
        meta: {
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Registered/Information.vue')
      },
      {
        path: 'employee',
        name: 'oc-registered-employee-list',
        meta: {
          access: 'viewEmployeeTab',
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Registered/EmployeeList.vue')
      },
      {
        path: 'employee/:employee_id/systematic',
        name: 'oc-registered-employee-item-systematic',
        meta: {
          access: 'viewEmployeeTab',
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Registered/Employee.vue')
      },
      {
        path: 'employee/:employee_id/unsystematic',
        name: 'oc-registered-employee-item-unsystematic',
        meta: {
          access: 'viewEmployeeTab',
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Registered/Employee.vue')
      },
      {
        path: 'branch',
        name: 'oc-registered-branch-list',
        meta: {
          access: 'viewBranchTab',
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Registered/BranchList.vue')
      },
      {
        path: 'branch/:branch_id',
        name: 'oc-registered-branch-item',
        meta: {
          access: 'viewBranchTab',
          groupName: 'menu.certificationAgency'
        },
        component: () => import('@/views/CertificationAgency/Registered/Branch.vue')
      }
    ]
  }
]
