export default [
  {
    path: '/measuring-laboratory',
    name: 'measuringLaboratoryList',
    meta: {
      groupName: 'menu.measuringLaboratory'
    },
    redirect: { name: 'vl-registered' },
    component: () => import('@/views/MeasuringLaboratory/Table.vue'),
    children: [
      {
        path: 'statement',
        name: 'vl-statement',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
      },
      {
        path: 'registered',
        name: 'vl-registered',
        meta: {
          groupName: 'menu.measuringLaboratory'
        }
      }
    ]
  },
  {
    path: '/measuring-laboratory/statement/:id',
    name: 'measuringLaboratoryStatement',
    redirect: { name: 'vl-statement-information' },
    component: () => import('@/views/MeasuringLaboratory/Moderation/Index.vue'),
    children: [
      {
        path: 'information',
        name: 'vl-statement-information',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
        component: () => import('@/views/MeasuringLaboratory/Moderation/Information.vue')
      },
      {
        path: 'branch',
        name: 'vl-statement-branch-list',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
        component: () => import('@/views/MeasuringLaboratory/Moderation/BranchList.vue')
      },
      {
        path: 'branch/:branch_id',
        name: 'vl-statement-branch-item',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
        component: () => import('@/views/MeasuringLaboratory/Moderation/Branch.vue')
      }
    ]
  },
  {
    path: '/measuring-laboratory/registered/:id',
    name: 'measuringLaboratoryRegistered',
    redirect: { name: 'vl-registered-information' },
    component: () => import('@/views/MeasuringLaboratory/Registered/Index.vue'),
    children: [
      {
        path: 'information',
        name: 'vl-registered-information',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
        component: () => import('@/views/MeasuringLaboratory/Registered/Information.vue')
      },
      {
        path: 'branch',
        name: 'vl-registered-branch-list',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
        component: () => import('@/views/MeasuringLaboratory/Registered/BranchList.vue')
      },
      {
        path: 'branch/:branch_id',
        name: 'vl-registered-branch-item',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
        component: () => import('@/views/MeasuringLaboratory/Registered/Branch.vue')
      },
      {
        path: 'statement-branch/:branch_id',
        name: 'vl-registered-statement-branch-item',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
        component: () => import('@/views/MeasuringLaboratory/Registered/StatementBranch.vue')
      },
      {
        path: 'employee',
        name: 'vl-registered-employee-list',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
        component: () => import('@/views/MeasuringLaboratory/Registered/EmployeeList.vue')
      },
      {
        path: 'employee/:employee_id',
        name: 'vl-registered-employee-item',
        meta: {
          groupName: 'menu.measuringLaboratory'
        },
        component: () => import('@/views/MeasuringLaboratory/Registered/Employee.vue')
      }
    ]
  }
]