import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { registeredCertificationAgency as endpoint } from '@/configs/endpoints'

const store = 'registeredCertificationAgency'

export default {
  state: () => ({
    certificationAgency: {},

    certificationAgencyEmployeeArray: [],
    certificationAgencyEmployeeArrayUnsystematic: [],
    certificationAgencyEmployee: {},

    certificationAgencyBranchArray: [],
    certificationAgencyBranch: {}
  }),

  getters: {
    registeredEmployeeArray: state => {
      let systematic = state.certificationAgencyEmployeeArray.map(e => {
        return {
          id: e.id,
          position: e.position,
          full_name: e.full_name,
          is_systematic: true,
          systematic_label: 'Має обліковий запис'
        }
      })
      let unsystematic = state.certificationAgencyEmployeeArrayUnsystematic.map(e => {
        return {
          id: e.id,
          position: e.position,
          full_name: `${e.last_name} ${e.first_name} ${e.middle_name}`,
          is_systematic: false,
          systematic_label: 'Без облікового запису'
        }
      })
      return systematic.concat(unsystematic)
    },
    employee: state => {
      if (state.certificationAgencyEmployee.statement_employee_status) {
        let {
          educational_institution,
          education_document_number,
          education_document_issued_date,
          specialization,
          raising_qualification_document_number,
          direction_raising_qualification,
          raising_qualification_date,
          inn,
          serial
        } = state.certificationAgencyEmployee

        state.certificationAgencyEmployee.user_educations = [
          {
            educational_institution,
            type_education_document: 1,
            number: education_document_number,
            issued_date: education_document_issued_date,
            speciality: specialization
          },
          {
            educational_institution: '',
            type_education_document: 2,
            number: raising_qualification_document_number,
            issued_date: raising_qualification_date,
            speciality: direction_raising_qualification
          }
        ]
        state.certificationAgencyEmployee.user_passports = [{ inn, serial }]
      }

      return state.certificationAgencyEmployee
    }
  },

  actions: {
    async getRegisteredCertificationAgency (context, id) {
      const response = await api.get(endpoint.registeredCertificationAgency(id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgency', data: response.data })
      }
    },

    async getRegisteredCertificationAgencyEmployeeList (context, data) {
      const response = await api.get(endpoint.registeredCertificationAgencyEmployeeList(data.id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyEmployeeArray', data: response.data.results })
      }
    },

    async getRegisteredCertificationAgencyEmployeeListUnsystematic (context, data) {
      const response = await api.get(endpoint.registeredCertificationAgencyEmployeeListUnsystematic(data.id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyEmployeeArrayUnsystematic', data: response.data.results })
      }
    },

    async getCertificationAgencyEmployee (context, ids) {
      const response = await api.get(endpoint.registeredCertificationAgencyEmployee(ids))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyEmployee', data: response.data })
      }
    },

    async getCertificationAgencyEmployeeUnsystematic (context, ids) {
      const response = await api.get(endpoint.registeredCertificationAgencyEmployeeUnsystematic(ids))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyEmployee', data: response.data })
      }
    },

    async getRegisteredCertificationAgencyBranchList (context, data) {
      const response = await api.get(endpoint.registeredCertificationAgencyBranchList(data.id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyBranchArray', data: response.data.results })
      }
    },

    async getRegisteredCertificationAgencyBranch (context, ids) {
      const response = await api.get(endpoint.registeredCertificationAgencyBranch(ids))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyBranch', data: response.data })
      }
    }
  }
}

