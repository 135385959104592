import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import DashboardPlugin from './plugins/dashboard-plugin'
import Vuelidate from 'vuelidate'
import VueSwal from 'vue-swal'

import store from '@/store'

import authorization from './plugins/auth'
import api from './plugins/api'
import eds from './plugins/eds'


import notify from '@ananasbear/notify'
import validate from '@ananasbear/validation'
import i18n from '@/plugins/vue-i18n'
import moment from 'moment'

import '@/assets/sass/index.sass'

Vue.config.productionTip = false

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/uk'

import lodash from 'lodash'

// Photoswipe Gallery
import Photoswipe from 'vue-pswipe'
Vue.use(Photoswipe)

import Table from '@/components/Layouts/Table'
Vue.component('TableLayout', Table)

Vue.config.silent = true

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Vuelidate)
Vue.use(DatePicker)
Vue.use(VueSwal)


Vue.prototype.$eds = eds
Vue.prototype.$notification = notify
Vue.prototype.$api = api
Vue.prototype.$auth = authorization
Vue.prototype.$valid = validate
Vue.prototype.$moment = moment
Vue.prototype.$lodash = lodash

Vue.mixin({
  methods: {
    $isEval (param) {
      param = param.replaceAll('.', '?.')
      const element = eval('this.'+param)
      if (element || element === 0) {
        return element
      } else {
        return ''
      }
    }
  }
})

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
