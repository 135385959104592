export default {
  btn: {
    correctly: 'Correctly',
    wrong: 'Wrong',
    comment: 'Comment',
    endModeration: 'End Moderation',
    confirmInformation: 'Confirm Information',
    sendToRevision: 'Send to Revision',
    nextStep: 'Next Step',
    prevStep: 'Previous Step',
    sign: 'Sign',
    clear: 'Clear',
    toMain: 'To Main',
    leaveComment: 'Leave Comment',
    close: 'Close',
    openInNewTab: 'Open in New Tab',
    cancel: 'Cancel',
    save: 'Save',
    set: 'Apply',
    search: 'Search',
    edit: 'Edit'
  },
}
