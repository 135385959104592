import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { certificateAgency as endpoint } from '@/configs/endpoints'
const store = 'certificationAgency'
import { access } from '@/configs/permissions'
import { questionGroupOC as questionGroup} from '@/configs/constants'
import router from '@/router'

export default {
  state: () => ({
    certificationAgencyArray: {
      results: [],
      count: 0
    },

    certificationAgency: {
      files: []
    },
    certificationAgencyQuestion: [],
    certificationAgencyQuestionFile: [],

    certificationAgencyEmployeeArray: [],
    certificationAgencyEmployeeQuestion: [],

    certificationAgencyBranchArray: [],
    certificationAgencyBranchQuestion: [],

    decisionCommentDialog: {
      isShow: false,
      text: '',
      isReadonly: false,
      comment: ''
    },
    decisionButtonLoader: {
      isLoading: false,
      id: null,
      decision: null
    },
    isLoad: false,
    isReadonlyStatement: true
  }),
  actions: {
    async getCertificationAgencyList (context, filter) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      const response = await api.get(`${endpoint.getCertificationAgencyArray}?${ filter }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyArray', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
    },

    async getRegisteredCertificationAgencyList (context, filter) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      const response = await api.get(`${endpoint.getRegisteredCertificationAgencyArray}?${ filter }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyArray', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
    },

    async getCertificationAgency (context, id) {
      const response = await api.get(endpoint.certificationAgency(id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgency', data: response.data })
      }
    },

    async updateCertificationAgency (context, data) {
      const response = await api.patch(endpoint.certificationAgency(data.id), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgency', data: response.data })
        if (response.data.statement_step === 4) {
          router.push({ name: 'certificationAgencyRegistered', params: { id: data.id } })
        }
      }
    },

    async updateMediaCertificationAgency (context, data) {
      const response = await api.post(endpoint.uploadMediaCertificationAgency(data.id), { body: data.body })
      if (await requestValidation(response)) {
        context.dispatch('getCertificationAgency', data.id)
      }
    },

    async deleteMediaCertificationAgency (context, data) {
      const response = await api.post(endpoint.removeMediaCertificationAgency(data.id), { body: data.body })
      if (await requestValidation(response)) {
        context.dispatch('getCertificationAgency', data.id)
      }
    },

    // OC information ----------------------------------------------------------

    async getCertificationAgencyQuestion (context, id) {
      const response = await api.get(endpoint.certificationAgencyQuestion(id))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_BY_GROUP', {
          store,
          state: 'certificationAgencyQuestion',
          data: response.data,
          groupConfig: {
            1: questionGroup.infoOC[1],
            2: questionGroup.infoOC[2],
            3: questionGroup.infoOC[3],
          }
        })
      }
    },

    async getCertificationAgencyQuestionFile (context, id) {
      const response = await api.get(endpoint.certificationAgencyQuestionFile(id))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_FILE_BY_GROUP', {
          store,
          state: 'certificationAgencyQuestion',
          data: response.data,
          groupConfig: {
            5: questionGroup.infoOC[5]
          }
        })
      }
    },

    async getCertificationAgencyQuestionDirectorFile (context, id) {
      const response = await api.get(endpoint.certificationAgencyQuestionDirectorFile(id))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_FILE_BY_GROUP', {
          store,
          state: 'certificationAgencyQuestion',
          data: response.data,
          groupConfig: {
            4: questionGroup.infoOC[4]
          }
        })
      }
    },

    async setCertificationAgencyDecision (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateCertificationAgencyQuestion(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'certificationAgencyQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    async setCertificationAgencyDecisionFile (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateCertificationAgencyQuestionFile(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'certificationAgencyQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    async setCertificationAgencyDecisionDirectorFile (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateCertificationAgencyQuestionDirectorFile(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'certificationAgencyQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    // OC Employee -------------------------------------------------------------

    async getCertificationAgencyEmployeeList (context, data) {
      const response = await api.get(endpoint.certificationAgencyEmployeeList(data))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyEmployeeArray', data: response.data })
      }
    },

    async getCertificationAgencyEmployeeQuestion (context, ids) {
      const response = await api.get(endpoint.certificationAgencyEmployeeQuestion(ids))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_BY_GROUP', {
          store,
          state: 'certificationAgencyEmployeeQuestion',
          data: response.data,
          groupConfig: {
            1: questionGroup.employeeOC[1],
            2: questionGroup.employeeOC[2],
            3: questionGroup.employeeOC[3]
          }
        })
      }
    },

    async setCertificationAgencyEmployeeDecision (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateCertificationAgencyEmployeeQuestion(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'certificationAgencyEmployeeQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    async getCertificationAgencyEmployeeQuestionFile (context, id) {
      const response = await api.get(endpoint.certificationAgencyEmployeeQuestionFile(id))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_FILE_BY_GROUP', {
          store,
          state: 'certificationAgencyEmployeeQuestion',
          data: response.data,
          groupConfig: {
            4: questionGroup.employeeOC[4],
            5: questionGroup.employeeOC[5]
          }
        })
      }
    },

    async setCertificationAgencyEmployeeQuestionFile (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateCertificationAgencyEmployeeQuestionFile(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'certificationAgencyEmployeeQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    // OC Branch ---------------------------------------------------------------

    async getCertificationAgencyBranchList (context, id) {
      const response = await api.get(endpoint.certificationAgencyBranch(id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'certificationAgencyBranchArray', data: response.data.results })
      }
    },

    async getCertificationAgencyBranchQuestion (context, ids) {
      const response = await api.get(endpoint.certificationAgencyBranchQuestion(ids))
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_BY_GROUP', {
          store,
          state: 'certificationAgencyBranchQuestion',
          data: response.data,
          groupConfig: questionGroup.branchOC
        })
      }
    },

    async setCertificationAgencyBranchDecision (context, data) {
      context.dispatch('showDecisionLoader', { id: data.ids.questionId, decision: data.body.answer })
      const response = await api.patch(endpoint.updateCertificationAgencyBranchQuestion(data.ids), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_QUESTION_DECISION', { store, state: 'certificationAgencyBranchQuestion', data: response.data })
      }
      context.dispatch('closeDecisionLoader')
    },

    async getFile (context, url) {
      const response = await api.get(url, { responseType: 'blob' })
      if (await requestValidation(response)) {
        // window.open(URL.createObjectURL(response.data), '_blank')
      }
    },
    // -------------------------------------------------------------------------

    showDecisionCommentDialog (context, data) {
      context.commit('SET_DATA', { store, state: 'decisionCommentDialog', data: { isShow: true, ...data } })
    },
    closeDecisionCommentDialog (context) {
      context.commit('SET_DATA', { store, state: 'decisionCommentDialog', data: { isShow: false, text: '', isReadonly: false, comment: '' } })
    },

    showDecisionLoader (context, data) {
      context.commit('SET_DATA', { store, state: 'decisionButtonLoader', data: { isLoading: true, ...data } })
    },
    closeDecisionLoader (context) {
      context.commit('SET_DATA', { store, state: 'decisionButtonLoader', data: { isLoading: false, id: null, decision: null } })
    },

    setReadonlyOCStatement (context) {
      let isReadonly = true
      let status = context.state.certificationAgency.statement_step

      switch (true) {
        case access('moderationActionNAAUWorker'):
          isReadonly = status !== 6
          break
        case access('moderationActionMIUWorker'):
          isReadonly = status !== 7
          break
        default:
          isReadonly = true
          break
      }
      context.commit('SET_DATA', { store, state: 'isReadonlyStatement', data: isReadonly })
    }

  }
}
