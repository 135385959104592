import api from '@/plugins/api'
import { user as endpoint } from '@/configs/endpoints'
import requestValidation from '@/configs/requestValidation'
import router from '@/router'
import notify from '@ananasbear/notify'
import i18n from '@/plugins/vue-i18n'
const store = 'user'

export default {
  state: () => ({
    users: {},
    user: {}
  }),
  actions: {
    async getUsers (context, filter) {
      const params = new URLSearchParams()
      for (let field in filter) {
        filter[field] && params.append(field, filter[field])
      }
      const response = await api.get(`${endpoint.user()}?${ params }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'users', data: response.data })
      }
    },

    async getUser (context, id) {
      const response = await api.get(`${endpoint.user(id)}`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'user', data: response.data })
      }
    },

    async createUser (context, data) {
      const response = await api.post(endpoint.user(), { body: data.body })
      if (await requestValidation(response) && data.redirectToInfoPage) {
        notify.success(i18n.t('notify.successUserCreated'))
        router.push({ name: 'user-info', params: { id: response.data.id } })
      }
    },

    async updateUser (context, data) {
      const response = await api.patch(endpoint.user(data.id), { body: data.body })
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'user', data: response.data })
        notify.success(i18n.t('notify.successUserUpdated'))
        if (data.redirectToInfoPage) {
          router.push({ name: 'user-info', params: { id: response.data.id } })
        }
      }
    }
  }
}