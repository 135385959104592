import { directories, directory } from '@/configs/endpoints'
import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import i18n from '@/plugins/vue-i18n'

const store = 'directory'

export default {
  state: () => ({
    vehicleMark: [],
    vehicleModel: [],
    vehicleModelByMark: [],
    manufacturerAddress: [],
    manufacturerFactory: [],
    region: [],
    cityByRegion: [],
    certificationAgency: [],
    cylinderArrangement: [],
    engineManufacturerCompany: [],
    engineWorkingPrinciple: [],
    fuelType: [],
    identificationMethod: [],
    identificationPlace: [],
    laboratoryBranchOffice: [],
    measuringLaboratory: [],
    vehicleAxis: [],
    vehicleCategory: [],
    vehicleColor: [],
    vehicleDoorLocation: [],
    carcassType: [],
    transmissionType: [],
    engineManufacturer: [],
    workingPrinciple: [],
    manufacturerCountry: [],
    vehicleClassList: [
      { value: 'I', name: 'I' },
      { value: 'II', name: 'II' },
      { value: 'III', name: 'III' },
      { value: 'A', name: 'A' },
      { value: 'B', name: 'B' },
      { value: 'School', name: 'Шкільний' },
      { value: 'Disabled', name: 'Інвалідний' },
      { value: '-', name: '-' }
    ],
    permissibleLegalWeightList: [
      { name: 'Реєстраційна', value: true },
      { name: 'Експлуатаційна', value: false }
    ],
    permissibleTrafficRuleList: [
      { name: 'У міжнародному дорожньому русі', value: true },
      { name: 'У національному дорожньому русі', value: false }
    ],
    identificationTypeCode : [
      { name: 'M1', id: 'M1' },
      { name: 'M2', id: 'M2' }
    ],
    correspondTechRules : [
      { name: '4', value: '4' },
      { name: '11', value: '11' },
      { name: '4 + 11', value: '4 and 11' },
    ],
    correspondMarkType: [
      {name : 'Ні' , id : 0},
      {name : 'Так' , id : 1},
    ],
    approvedType: [
      {name : 'З обмеженням строку дії затвердження типу до ' , id : 'date'},
      {name : 'З обмеженням щодо кількості ' , id : 'amount'},
    ],
    noiseInspectionType: [
      {
        name: 'Нерухомий КТЗ',
        value: 'stationary'
      },
      {
        name: 'Рухомий КТЗ',
        value: 'moving'
      },
    ],
    inspectionTypesGas: [
      {
        name: i18n.t('vehicleCharacteristic.type_I_or_ESC'),
        value: 'type_I_or_ESC'
      },
      {
        name: i18n.t('vehicleCharacteristic.type_I_euro_5'),
        value: 'type_I_euro_5'
      },
      {
        name: i18n.t('vehicleCharacteristic.type_ETC'),
        value: 'type_ETC'
      }
    ],
    certificateType: [
      {
        name: 'Сертифікат відповідності',
        value: 1
      },
      {
        name: 'Сертифікат типу',
        value: 2
      },
    ],
    destinationType: [
      {
        name: 'Підрозділу виробника',
        value: 1
      },
      {
        name: 'Уповноваженого представника виробника',
        value: 2
      },
      {
        name: 'Органу, що виконував процедуру затвердження',
        value: 3
      }
    ],
    inspectionTypesSmoke: [
      {
        id: 0,
        name: i18n.t('vehicleCharacteristic.urbanCycle'),
      },
      {
        id: 1,
        name: i18n.t('vehicleCharacteristic.acceleratedUrbanCycle'),
      },
      {
        id: 2,
        name: i18n.t('vehicleCharacteristic.combinedCycle'),
      },
      {
        id: 3,
        name: i18n.t('vehicleCharacteristic.combinedAdjustedValue'),
      },
      {
        id: 4,
        name: i18n.t('vehicleCharacteristic.electricAndHybridVehicles'),
      }
    ]
  }),
  actions: {
    getDirectories(context) {
      for (const directory of directories) {
        api.get(directory.endpoint).then(response => {
          let data;
          if (requestValidation(response)) {
            if (Array.isArray(response.data?.results)) {
              data = response.data.results.filter(i => i.is_active || i.is_active === undefined)
            } else {
              data = Array.isArray(response.data) && response.data.filter(i => i.is_active || i.is_active === undefined)
            }
            context.commit('SET_DATA', { store, state: directory.state, data: data })
          }
        })
      }
    },
    async getDirectoryByEndpoint(context, data) {
      const response = await api.get(`${ directory[data.endpoint] }`)
      if (await requestValidation(response)) {
        const result = Array.isArray(response.data) && response.data.filter(i => i.is_active || i.is_active === undefined)
        context.commit('SET_DATA', { store, state: data.state || data.endpoint, data: result })
      }
    },
    async getDirectoryByParams(context, data) {
      const response = await api.get(`${ directory[data.endpoint](data.id) }`)
      if (await requestValidation(response)) {
        const result = Array.isArray(response.data) && response.data.filter(i => i.is_active || i.is_active === undefined)
        context.commit('SET_DATA', { store, state: data.endpoint, data: result })
      }
    },
    async getDirectoryById (context, data) {
      const response = await api.get(`${ directory[data.endpoint](data.id) }`)
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: data.state || data.endpoint, data: response.data })
        return response.data
      }
    },
  },
  getters: {
    directoryById: state => (directory, id) => state[directory].find(item => Number(item.id) === Number(id)),
    directoryByValue: state => (directory, value) => state[directory].find(item => item.value === value),
    directory: state => (directory, value, argument) => {
      const foundItem = state[directory].find(item => item[argument ? argument : 'id'] === value)
      return foundItem || {}
    },
    certificationAgencySelectArray: state => state.certificationAgency.map(e => {
      return {
        id: e.id,
        name: e.name
      }
    })
  }
}
