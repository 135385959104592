import notify from '@ananasbear/notify'

export default async response => {
  switch (response.code) {
    case 200:
    case 201:
    case 204:
      return true
    case 400 || 401 || 403: {
      const body = await response.data.json()
      let isShowNotify = false
      for (const error of Object.entries(body)) {
        let checkTypeError = Array.isArray(error[1]) ? error[1][0] : error[1]
        if (!isShowNotify) {
          notify.warning(`notify.requestErrors.${
            checkTypeError.replace(/ |,|:|;|"/g, '_').replaceAll(/[.()\[\]+|-]/g, '')
          }`)
        }

        isShowNotify = true
      }

      return false
    }
    default:
      return false
  }
}
