import api from '@/plugins/api'
import requestValidation from '@/configs/requestValidation'
import { vehicle as endpoint } from '@/configs/endpoints'
const store = 'vehicle'

export default {
  state: () => ({
    vehicle: [],
  }),
  actions: {
    async createVehicle (context, body) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      console.log(endpoint.vehicle())

      const response = await api.post( endpoint.vehicle(), {body: body})

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'vehicle', data: response.data })
      }

      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
      return response.data
    },
    async getVehicleById (context, id) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      context.commit('SET_DATA', { store, state: 'vehicle', data: {} })

      const response = await api.get( endpoint.vehicle(id))
      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'vehicle', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
      return response.data
    },
    async updateVehicleModel (context, data) {
      context.commit('SET_DATA', { store, state: 'isLoad', data: true })
      const response = await api.patch(endpoint.vehicle(data.id), { body: data.body })

      if (await requestValidation(response)) {
        context.commit('SET_DATA', { store, state: 'vehicle', data: response.data })
      }
      context.commit('SET_DATA', { store, state: 'isLoad', data: false })
      return response.data
    }
  },
}
