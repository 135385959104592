export default {
  vehicleCharacteristic: {
    generalDesignCharacteristics: 'Загальні конструктивні характеристики',
    vehicleMasses: 'Маси ТЗ',
    wheelbase: '4. Колісна база',
    trailerMaxWeight: '18. Максимальна маса причепа',
    powerTrain: 'Силова установка',
    speed: 'Швидкість',
    brakeSystem: 'Гальмівна система',
    body: 'Кузов',
    couplingDevice: 'Зчіпний пристрій',
    axesAndSuspension: 'Осі та підвіска',
    solidParts: 'Тверді частки',
    environmental: 'Екологічні показники',
    'type_I_or_ESC': '1.1 Випробовування типу I або ESC',
    'type_I_euro_5': '1.2 Випробовування типу I (Євро 5 або 6)',
    'type_ETC': '2.Випробування ETC (якщо застосовно):',
    typeInspection: 'Тип випробування',
    stationary: 'Нерухомий КТЗ',
    move: 'Рухомий КТЗ',
    onStationaryVehicle: 'на нерухомому КТЗ',
    whileVehicleMoving: 'під час руху КТЗ',
    noiseLevel: '46.Рівень шуму: зазначити нормативний документ, застосований під час виконання процедур затвердження типу',
    urbanCycle: 'Міський цикл',
    acceleratedUrbanCycle: 'Прискорений міський цикл',
    combinedCycle: 'Комбінований цикл',
    combinedAdjustedValue: 'Приведене, комбіноване значення',
    electricAndHybridVehicles: 'Електричні та гібридні КТЗ',
    typeCertification: 'Вимірювання типу',
    noiseMeasurement: 'Вимірювання шуму',
    smokeMeasurement: 'Вимірювання димності',
    CO2Emissions: 'Викиди CO2',
    fuelConsumption: 'Витрата палива',
    combinedAdjustedEnergyUsage: 'Витрата електричної енергії (приведене, комбіноване значення)',
    drivingRange: 'Запас ходу',
    rotationFrequency: 'За частоти обертання',
    maximumPermissibleWeights: '16. Максимальні технічно припустимі маси',
    CO2EmissionsFuelConsumptionEnergyUsage: '49.Викиди CO2 / витрата палива / витрата електричної енергії: вказати нормативний документ, застосований під час виконання процедур затвердження типу',
    other: 'Інше',
    opacity: 'Димність',
    solidParticlesMass: 'Тверді частки (маса)',
    solidParticlesCount: 'Тверді частки (кількість)',
    opacityCorrectionCoefficient: '48.1. Димність (скоригований коефіцієнт поглинання)',
    axleGroups: 'Групи вісей',
    axle: 'Вісь',
    registration: 'Реєстраційна',
    operational: 'Експлуатаційна',
    internationalRoadTraffic: 'Міжнародний дорожній рух',
    nationalRoadTraffic: 'Національному дорожньому русі',
  }
}
