<template lang="pug">
  div
    v-card.card-shadow.border-radius-xl
      v-card-text.px-0.py-0
        v-data-table(
          :headers="filteredHeaders"
          :items="items"
          :search="search"
          :page.sync="page"
          hide-default-footer
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
          loading-text="Завантаження..."
          no-results-text="Дані не знайдено"
          no-data-text="Даних немає"
          :loading="isLoad"

          :must-sort="hasSorting"
          :server-items-length="totalCountItem"
          :options.sync="options"
        ).table

          template(v-slot:no-data)
            NothingFound(height="300" )

          template(v-slot:item.addressString="{ item }")
            span {{ addressString(item.address) }}

          template(v-slot:item.position="{ item }")
            span {{ item.userprofile.position }}

          template(v-slot:item.organization="{ item }")
            span {{ item.userprofile.organization_name }}

          template(v-slot:item.full_name="{ item }")
            span {{ fullName(item, true) }}

          template(v-slot:item.accreditation_certificate_date_end="{ item }")
            span {{ $moment(item.accreditation_certificate_date_end).format("DD.MM.YYYY") }}

          template(v-slot:item.date_end="{ item }")
            span {{ $moment(item.date_end).format("DD.MM.YYYY") }}

          template(v-slot:item.created_at="{ item }")
            span {{ $moment(item.created_at).format("DD.MM.YYYY") }}

          template(v-slot:item.is_active="{ item }")
            v-chip(
              :color="statuses[typeStatus](item.is_active).color"
              :text-color="statuses[typeStatus](item.statement_step).textColor"
              label
              small
            ).align-self-start.border-radius-xs.font-weight-bold
              v-icon(size="30" style="width: 10px; height: 10px").mr-2 mdi-circle-small
              span {{ $t(statuses[typeStatus](item.is_active).text) }}

          template(v-slot:item.status="{ item }")
            v-chip(
              :color="statuses[typeStatus](item.status_document).color"
              :text-color="statuses[typeStatus](item.statement_step).textColor"
              label
              small
            ).align-self-start.border-radius-xs.font-weight-bold
              v-icon(size="30" style="width: 10px; height: 10px").mr-2 mdi-circle-small
              span {{ $t(statuses[typeStatus](item.status_document).text) }}

          template(v-slot:item.statement_step="{ item }")
            div.d-flex
              v-chip(
                v-if="item.statement_step !== -1"
                :color="statusObject(item.statement_step).color"
                :text-color="statusObject(item.statement_step).textColor"
                label
                small
              ).align-self-start.border-radius-xs.font-weight-bold
                v-icon(size="30" style="width: 10px; height: 10px").mr-2 mdi-circle-small
                span {{ $t(statusObject(item.statement_step).text) }}

              v-chip(
                v-if="item.has_moderation_branches"
                :color="statusModerationNewBranch.color"
                :text-color="statusModerationNewBranch.textColor"
                label
                small
              ).align-self-start.border-radius-xs.font-weight-bold.ml-4
                v-icon(size="30" style="width: 10px; height: 10px").mr-2 mdi-circle-small
                span {{ $t(statusModerationNewBranch.text) }}

          template(v-slot:item.actions="{ item }" v-if="isShowDetailedButton" )
            v-btn(
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              @click="$router.push(`${ detailedRoute + item.id}`)"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e")
              v-icon(size="18").text-black fas fa-info

      v-card-actions.card-padding
        v-row(v-if="!!items.length")
          v-col(cols="6").ml-auto.d-flex.justify-end
            v-pagination(
              v-if="count > 1"
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#cb0c9f"
              v-model="page"
              :length="count"
              @input="change"
              circle
            )

</template>

<script>
import statuses from '@/configs/statuses'
import NothingFound from '@/components/project/Moderation/NothingFound.vue'

export default {
  name: 'TableLayout',
  components: { NothingFound },
  props: {
    items: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
    count: { type: Number, default: 1 },
    pageProp: { type: Number, default: 1 },
    isLoad: { type: Boolean, default: false },
    detailedRoute: { type: String, default: null },
    totalCountItem: { type: Number, default: 0 },
    hasSorting: { type: Boolean, default: false },
    isShowDetailedButton: { type: Boolean, default: true },
    typeStatus: { type: String }
  },
  data () {
    return {
      itemsPerPage: 20,
      search: '',
      page: this.pageProp,
      statuses,
      statusForApprove: 4,
      options: {
        sortBy: ['created_at'],
        sortDesc: [true]
      },
      statusModerationNewBranch: {
        color: 'danger',
        textColor: '#FFFFFF',
        text: 'statuses.moderationBranch.statementBranchModeration'
      }
    }
  },
  computed: {
    filteredHeaders () {
      if (!this.isShowDetailedButton) {
        return this.headers.filter(item => item.value !== 'actions')
      }
      return this.headers
    },
    statusObject () {
      return status => this.statuses[this.typeStatus](status || this.statusForApprove)
    },

    // return address string from address object
    addressString () {
      return address => {
        let city = address.city ? `${this.$t('units.address.city', { city: address.city } )}, ` : ''
        let region = address.region ? `${this.$t('units.address.region', { region: address.region } )}, ` : ''
        let street = address.street ? this.$t('units.address.street', { street: address.street } ) : ''
        let house = address.house ? `${ address.house }, ` : ''
        let flat = address.flat ? `${this.$t('units.address.flat', { flat: address.flat } )}, ` : ''
        let index = address.index ? address.index : ''
        return `${city} ${region} ${street} ${house} ${flat} ${index}`
      }
    },
    fullName () {
      return (item, isShort) => {
        let lastName = item.last_name ? `${item.last_name} ` : ''
        let firstName = item.first_name ? `${item.first_name} ` : ''
        let middleName = item.middle_name ? `${item.middle_name} ` : ''
        return isShort ? `${lastName} ${firstName[0]}. ${middleName[0]}.` : `${lastName} ${firstName} ${middleName}`
      }
    }
  },
  watch: {
    // watch the sorting change and change query params.
    options: {
      handler (val) {
        if (this.hasSorting) {
          const query = Object.assign({}, this.$route.query)
          query.ordering = `${val.sortDesc[0] ? '-' : ''}${val.sortBy[0]}`
          this.$router.push({ query: query }, () => {})
        }
      },
      deep: true
    },
    pageProp (val) {
      this.page = val
    }
  },
  mounted() {
    // if table has sorting than set order and direction sorting
    if (this.hasSorting) this.setOrderingByQuery()
  },
  methods: {
    change (page) {
      const query = Object.assign({}, this.$route.query)
      query.page = String(page)
      this.$router.push({ query: query }, () => {})
      this.$emit('changePage', query)
    },
    // set sortBy and sortDesc props from query params
    setOrderingByQuery () {
      let queryOrder = this.$route.query.ordering
      if (queryOrder) {
        let sortDesc = queryOrder[0] === '-'
        let sortBy = sortDesc ? queryOrder.substr(1) : queryOrder
        this.options = {
          sortBy: [sortBy],
          sortDesc: [sortDesc]
        }
      }
    }
  }
}
</script>
