export default [
  {
    path: '/user',
    name: 'user',
    meta: {
      access: 'viewPageRegistryUser',
      groupName: 'registryUser'
    },
    component: () => import('@/views/RegistryUser/Table.vue')
  },
  {
    path: '/user/:id',
    name: 'user-info',
    meta: {
      groupName: 'registryUser'
    },
    component: () => import('@/views/RegistryUser/Info.vue')
  },
  {
    path: '/user/:id/edit',
    name: 'user-edit',
    meta: {
      groupName: 'registryUser'
    },
    component: () => import('@/views/RegistryUser/Form.vue')
  }
]