<template lang="pug">
  include ../plugins/pug/template

  v-app.bg-white
    v-main.auth-pages
      div.header-auth.position-relative.border-radius-xl.min-vh-100
        v-container.py-0
          v-row
            v-col(lg="4" md="7" @keyup.enter="sign").d-flex.flex-column.mx-lg-0.mx-auto
              v-card-text(v-if="isViewFinger")
                div.text-left
                  +btn('primary', 'btn.back', 'mdi-arrow-left')(
                    @click="goBack" :loading="isLoad" style="border: none; padding: 0")

              v-card(v-else).mt-sm-8.mt-md-5.mt-7.bg-transparent
                div.card-padding.pb-0
                  h4.text-h4.font-weight-bolder.text-typo.mb-2 {{ $t('authorization') }}
                  p.mb-0.text-body {{ $t('enterEmailPassword') }}

                div(v-if="errors.length").card-padding
                  v-alert(color="error" text dense).mb-0
                    ul
                      li(v-for="error in errors" :key="error" ) {{ error }}

                div.card-padding.pb-4
                  +field({ model:'body.username', label: 'email' }).mb-4
                  +field({ model:'body.password', label: 'password' })(
                    :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="isShowPassword ? 'text' : 'password'"
                    @click:append="isShowPassword = !isShowPassword"
                  )
                  +btn('#5e72e4', 'signIn')(@click="sign" :loading="isLoad")

            v-col(cols="6").d-lg-flex.d-none.h-100.my-auto.pe-0.pt-0.position-absolute.top-0.end-0.text-center.justify-center.flex-column
              div.position-relative.bg-gradient-primary.h-100.ma-4.px-16.border-radius-lg.d-flex.flex-column.justify-center
                v-img(
                  src="@/assets/img/shapes/pattern-lines.svg"
                  alt="pattern-lines").position-absolute.opacity-4.start-0.h-100.w-100
                div.position-relative
                  v-img(
                    src="@/assets/img/illustrations/rocket-white.png"
                    alt="rocket"
                    max-width="500").w-100.position-relative.z-index-2.mx-auto
                h4.text-h4.mt-10.text-white.font-weight-bolder.mb-2 {{ $t('certificationRegistry') }}
                p.text-white {{ $t('signInWelcome') }}

</template>

<script>
import { signIn } from '@/configs/validationRules'
export default {
  name: 'sign-in',
  data () {
    return {
      isShowPassword: false,
      isLoad: false,
      isViewFinger: false,
      isViewQR: false,
      isViewCode: false,
      body: {
        username: '',
        password: '',
      },
      errors: []
    };
  },
  validations: {
    body: signIn
  },
  mounted() {
    this.checkAuth()
  },
  methods: {
    goBack() {
      localStorage.clear()
      document.cookie = 'TOKENTOTP=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      window.location.reload()
    },
    async sign() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.errors = []
        this.isLoad = true
        const auth = await this.$auth.byCredits(this.body)

        let codeError = [400, 401, 404]
        if (codeError.includes(auth.code)) {
          this.isLoad = false
          let error = await auth.data.json()
          for (let field in error) {
            error[field].forEach(e => {
              this.errors.push(this.$t(e.replace(/ /g, '_').replace('.', '')))
            })
          }
        }

        if (auth.type === 'general') {
          let path = decodeURIComponent(window.location.search.replace('?path=', '')) || '/'
          await this.$router.push(path)

          this.isLoad = false
        } else this.checkAuth()
      }
    },
    checkAuth() {
      switch (this.$auth.authoCheck()) {
        case 'U2F auth':
          // this.isViewFinger = true
          this.$auth
            .byU2F()
            .then((auth) => {
              this.isLoad = false
              if (auth.status) location = '/'
            })
            .catch((error) => {
              console.dir(error)
              this.$notification.warning(this.$t(`notify.${error.name} - ${error.message.replaceAll('. See: https://www.w3.org/TR/webauthn-2/#sctn-privacy-considerations-client.', '')}`))
              this.isLoad = false
              switch (error.code) {
                case 1:
                  console.log(`${error.type} - ${error.code}`, 'An error otherwise not enumerated here.')
                  break
                case 2:
                  console.log(
                    `${error.type} - ${error.code}`,
                    `One of the following reasons:
                      The visited URL doesn’t match the App ID.
                      The App ID does not conform with the rules for App ID’s
                      The U2F API is called with bad parameters (e.g. calling u2f.register with the parameters in the wrong order).
                    `
                  )
                  break
                case 3:
                  console.log(`${error.type} - ${error.code}`, 'Client configuration is not supported.')
                  break
                case 4:
                  console.log(
                    `${error.type} - ${error.code}`,
                    `The presented device is not eligible for this request.
                      For a registration request this may mean that the token is already registered,
                      and for a sign request it may mean that the token does not know the presented key handle.`
                  )
                  break
                case 5:
                  console.log(`${error.type} - ${error.code}`, `Timeout reached before request could be satisfied.`)
                  break
              }
            })
          break
        case 'U2F create':
          this.isViewFinger = true
          break
        case 'TOTP create':
          this.qr = this.$auth.getTOTPQR()
          this.isViewQR = true
          this.isViewCode = true
          break
        case 'TOTP auth':
          this.isViewCode = true
          break
      }
    },
  }
}
</script>
