import {
  required,
  requiredIf,
  minLength,
  maxLength,
  sameAs,
  numeric,
  maxValue,
  email,
  helpers
} from 'vuelidate/lib/validators'

const regexNumericDot = helpers.regex('alpha', /^[0-9.]+$/)
const regexLetters = helpers.regex('alpha', /^[а-щА-ЩЬьЮюЯяЇїІіЄєҐґ'.\‘\’\- ]*$/)
const phoneNumber = helpers.regex('alpha', /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im)


export const signIn = {
  username: { required },
  password: { required }
}

export const changePassword = {
  oldPassword: { required },
  newPassword: { required, minLength: minLength(8), maxLength: maxLength(128) },
  confirmNewPassword: { required, samePassword: sameAs('newPassword') }
}

export const changeProfileInfo = {
  first_name: { required },
  last_name: { required },
  middle_name: { required },
  userprofile: {
    phone_number: { required, phoneNumber }
  }
}

export const changeServiceInfo = {
  userprofile: {
    organization_name: { required },
    position: { required }
  }
}

export const user = context => {
  return {
    last_name: { required, regexLetters, maxLength: maxLength(150) },
    first_name: { required, regexLetters, maxLength: maxLength(150) },
    middle_name: { regexLetters, maxLength: maxLength(150) },
    userprofile: {
      organization_name: { maxLength: maxLength(100) },
      position: { maxLength: maxLength(40) }
    },
    email: {
      required: requiredIf(() => context.isCreateForm),
      email
    },
    password: {
      required: requiredIf(() => context.isCreateForm)
    }
  }
}

export const userPassword = {
  password: { required }
}

export const createCertificationType = {
  approved_type: { required },
  certification_agency: { required },
  correspond_mark_type: { required },
  correspond_tech_rules: { required },
  identification_place: { required },
  identification_type_code: { required },
  manufacturer_country: { required },
  manufacturer_factory: { required },
  registration_number: { required },
  vehicle_category: { required },
}

export const certificationTypeVehicle = {
  vehicle_model: { required },
  vehicle_mark: { required },
  vehicle_category: { required },
  vehicle_mark_type: { required },
}

export const vehicleDescription = {
  count_axles: { numeric, maxValue: maxValue(20) },
  count_wheels: { numeric, maxValue: maxValue(80) },
  wheel_base: { numeric, maxValue: maxValue(20000) },
  length: { numeric, maxValue: maxValue(999999) },
  width: { numeric, maxValue: maxValue(999999) },
  height: { numeric, maxValue: maxValue(999999) },
  weight: { numeric },
  permissible_weight: { numeric, maxValue: maxValue(999999) },
  max_combine_weight: { numeric, maxValue: maxValue(999999) },
  coupling_device_max_weight: { numeric },
  coupling_device_static_weight: { numeric },
  count_cylinders: { numeric },
  engine_capacity: { regexNumericDot },
  engine_power: { regexNumericDot, maxValue: maxValue(99999) },
  engine_rpm: { numeric, maxValue: maxValue(99999) },
  max_speed: { numeric, maxValue: maxValue(99999) },
  count_doors: { numeric, maxValue: maxValue(99999) },
  count_seats: { numeric, maxValue: maxValue(99999) }
}

export const characteristicTypeApproval = {
  trailer_max_weight_with_movable_coupling_device: {required, numeric},
  semi_trailer_max_weight: {required, numeric},
  trailer_max_weight_with_not_movable_coupling_device: {required, numeric},
  is_electric_car: {required},
  is_hybrid_car: {required},
  vehicle_color: {required},
  count_places_stationary: {required, numeric},
  euro_code: {required},
  legal_act_for_euro_rate: {required},
  smoke_inspection_absorption_level: {required},
}

export const decisionComment = {
  comment: { required }
}

export const noiseInspectionValidation = {
  legal_act: { required },
  engine_rpm: { required, numeric },
  noise_measurement_result: { required, numeric },
}

export const emissionRules = {
  type_I_euro_5or6 : {
  co: { required, numeric },
  thc: { required, numeric },
  nmhc: { required, numeric },
  nox: { required, numeric },
  thc_and_nox: { required, numeric },
  solid_particles_weight: { required, numeric },
  solid_particles_amount: { required, numeric },
  },
  type_I_or_ETC: {
      co: { required, numeric },
      hc: { required, numeric },
      nox: { required, numeric },
      hc_and_nox: { required, numeric },
      solid_particles: { required, numeric },
      smokiness: { required, numeric },
  },
}

export const vehicleMainInfo = {
  vehicle_mark: { required },
  vehicle_model: { required },
}

export const smokeInspection = {
  cycle_type: { required },
}

export const noiseInspection = {
  inspection_type: { required },
  measurement_mode: { required },
  noise_measurement_result: { required, numeric },
}

export const inspectionTypeMeasurement = {
  inspection_type: { required },
  co: { required, numeric },
  nox: { required, numeric },
}

export const createManualCertificate = {
  full_number: { required },
  created_at: { required },
  related_created_at: { required },
  vin_code: { required },
  certificate_owner_type: { required },
  certificate_owner: { required },
  related_certificate_owner_type: { required },
  related_certificate_owner: { required },
  creator: {
    first_name: { required },
    last_name: { required },
    middle_name: { required },
    position: { required },
  },
  address: {
    region: { required },
    index: { required, numeric },
    street: { required },
    house: { required },
    flat: { required, numeric },
    city: { required },
  },
  vehicle: {
    vehicle_mark: { required },
    vehicle_model: { required },
    identification_place: { required },
    identification_method: { required },
    vehicle_category: { required },
  },
}
