import theme from './theme'
import locales from '@/configs/locales'

export default {
  product: {
    name: 'Project',
    version: '0.0.1'
  },

  theme,
  locales
}
