export default {
  notify: {
    success: 'Виконано успішно',
    warning: 'Зверніть увагу',
    error: 'Помилка виконання',
    info: 'Повідомлення',
    successChangePassword: 'Пароль успішно змінено',
    errorChangePassword: 'Невірно введений старий пароль',
    errorCreateCertificationType: 'Унікальний номер документа вже використовується',
    characteristicCertificateTypeCharacteristicUpdate: 'Додаток до характеристики типу змінено',
    wrongFormatVinCode: 'Невірний формат. Дозволені лише літери та цифри',
    wrongFormatVinCodeQOI: 'Невірний формат. Q, O, I заборонені літери',
    moderation: {
      success: 'Ваше рішення збережене! Статус заявки успішно змінено.'
    },
    successChangeInfo: 'Інформацію успішно змінено',
    successUserCreated: 'Користувача успішно створено',
    successUserUpdated: 'Користувача успішно оновлено',

    requestErrors: {
      Incorrect_email_or_password: 'Неправильний email або пароль',
      The_sequence_part_of_number_should_start_from_0001: 'Порядкова частина номера повинна починатися з 0001',
      Type_approval_certificate_with_this_registration_number_already_exists: 'Сертифікат типу з таким реєстраційним номером вже існує',
      The_share_part_of_number_should_consist_of_2_digits: 'Остання частина номера повинна складатися з 2 цифр',
      User_with_this_email_address_already_exists: 'Користувач із цією електронною адресою вже існує'
    },
    validation: {
      required: 'Поле обов\'язкове', // vuelidate method name (email, minLength, ...)
      sameAs: 'Має бути таким же, як {eq}', // eq - имя переменной с которой мы сравниваем
      invalidEmail: 'Невірний email',
      length: 'Довжина поля не відповідає допустимому',
      samePassword: 'Паролі мають співпадати',
      minLength: 'Довжина поля замала',
      maxLength: 'Довжина поля завелика',
      numeric: 'Тільки цифри',
      regexNumericComma: 'Тільки цифри',
      minValue: 'Замале значення',
      maxValue: 'Завелике значення',
      greatEffort: 'Занадто велике зусилля, проведіть замір ще раз',
      email: 'Email некоректний, приклад example@domain.com',
      checkAvailable: 'Поле обов\'язкове до заповнення',
      regexNumericDot: 'Тільки числа та крапка',
      regexLetters: 'Тільки українська',
      phoneNumber: 'Невірний формат телефону',
    },
  },
}
