export default {
  vehicleCharacteristic: {
    generalDesignCharacteristics: 'General design characteristics',
    wheelCount: 'Number of wheels',
    countAxles: 'Number of axles',
    doubleWheelAxlePositions: 'Number and positions of axles with double wheels',
    steeringAxles: 'Steering axles (number, position numbers)',
    motorAxles: 'Motor axles (number, location, interconnection)',
    mainDimensions: 'Main dimensions',
    wheelbase: 'Wheelbase',
    length: 'Length',
    width: 'Width',
    height: 'Height',
    pinCouplingOffsets: 'Offsets of the pin coupling device relative to the rear axle (maximum and minimum)',
    maxPinCouplingOffset: 'Maximum offset of the pin coupling device relative to the rear axle',
    minPinCouplingOffset: 'Minimum offset of the pin coupling device relative to the rear axle',
    frontDimensionToCoupling: 'Distance between the front dimension of the vehicle and the center of the coupling device',
    couplingRearDimension: 'Distance between the center of the coupling device and the rear dimension of the vehicle',
    loadingSpaceLength: 'Loading space length',
    rearOverhang: 'Rear overhang',
    interAxleDistances: 'Distances between axles',
    vehicleMasses: 'Vehicle masses',
    kerbWeight: 'Curb weight of the vehicle',
    maxPermissibleWeight: 'Maximum permissible gross vehicle weight',
    registeredMaxPermissibleCombinedMass: 'Registered/operating maximum permissible combined mass of the vehicle combination',
    distributionOfMassByAxles: 'Distribution of mass by axles',
    permissibleAxleLoad: 'Permissible axle load on each axle',
    technicalPermissibleAxleLoad: 'Technical permissible axle load on each group of axles',
    registrationMaxPermissibleGroupAxisWeight: 'Registered/operating maximum permissible weight on each group of axles',
    maximumTrailerWeight: 'Maximum trailer weight',
    movingVerticalCouplingDevice: 'With a moving vertical coupling device',
    semiTrailer: 'Semi-trailer',
    stationaryVerticalCouplingDevice: 'With a stationary vertical coupling device',
    inoperativeBrakeSystem: 'In the absence of a working braking system',
    staticPinLoadCapacity: 'Technically permissible maximum static load on the coupling device',
    powerTrain: 'Powertrain',
    engineManufacturer: 'Engine manufacturer',
    engineCode: 'Engine code (indicated on the engine)',
    workingPrinciple: 'Working principle',
    electricTractor: 'Electric tractor',
    hybridTractor: 'Hybrid tractor',
    locationOfCylinders: 'Location of cylinders',
    numberOfCylinders: 'Number of cylinders',
    engineDisplacement: 'Engine displacement',
    fuelType: 'Type(s) of fuel',
    singleOrDualOrMultiFuel: 'Single/dual/multi-fuel',
    maxNetPower: 'Maximum net power',
    atRotationSpeed: 'at rotation speed',
    maximumPowerElectricMotor: 'Maximum power of electric motor:',
    gearboxType: 'Gearbox type',
    speed: 'Speed',
    maxSpeed: 'Maximum speed',
    axesAndSuspension: 'Axes and suspension',
    trackWidthOfEachSteeringAxis: 'Track width of each steering axis',
    trackWidthOtherAxles: 'Track width of all other axles',
    locationOfLiftingAxle: 'Location of lifting axle(s)',
    locationOfConstantLoadAxis: 'Location of constant load axis(es)',
    suspensionOnDriveAxle: 'Pneumatic suspension or equivalent devices on drive axle(s)',
    axleSuspension: 'Pneumatic suspension or equivalent devices on axle(s)',
    brakeSystem: 'Brake system',
    typeOfTrailerBrakeConnector: 'Type of trailer brake connector',
    trailerBrakeSystemPressure: 'Trailer brake system pressure',
    body: "Body",
    bodyTypeDesignation: 'Vehicle type by body type designation',
    classOfVehicle: 'Vehicle class',
    color: 'Vehicle color',
    countAndFeaturesOfDoors: 'Number and features of doors',
    seatingCapacity: 'Seating capacity (including driver)',
    seatsCount: 'Number of seats (including driver, crew members, priority seats)',
    crewSeatsCount: 'Number of crew member seats',
    numberOfPrioritySeats: 'Number of priority seats',
    stationaryUseSeats: 'Seats intended for use only in stationary vehicles',
    characteristicsValue: 'Characteristic value',
    environmentalIndicators: 'Environmental indicators',
    emissionStandardLevel: 'Designation of emission standard level',
    emissionStandardCode: 'Emission standard code for exhaust emissions of pollutants',
    emissionsStandard: 'Designation of the regulatory document that determines the level of pollutant emissions and smoke from exhaust gases used during the type approval procedures',
    noiseLevelRegulation: 'Noise level: regulatory document used during the type approval procedure',
    stationaryVehicle: 'Stationary vehicle',
    rotationFrequency: "Rotation frequency",
    whileMoving: 'While moving',
    typeIorESC: 'Type I or ESC testing',
    typeI: 'Type I testing (Euro 5 or 6)',
    solidParticles: 'Solid particles',
    opacity: 'Opacity',
    solidParticlesMass: 'Solid particles (mass)',
    SolidParticlesCount: 'Solid particles count',
    etcTest: 'ETC test (if applicable)',
    powerUnitsExcludingElectricVehicles: 'Power units excluding electric vehicles',
    urbanCycle: 'Urban cycle',
    CO2Emissions: 'CO2 emissions',
    fuelConsumption: 'Fuel consumption',
    acceleratedUrbanCycle: 'Accelerated urban cycle',
    combinedCycle: 'Combined cycle',
    normalizedCombinedValue: 'Normalized combined value',
    electricHybridVehicles: 'Electric and hybrid vehicles',
    electricEnergyConsumptionCombined: 'Electric energy consumption (combined value)',
    drivingRange: 'Driving range',
    powerConsumptionNormalized: 'Electric energy consumption (normalized value)',
    other: 'Other',
    notes: 'Notes',
    transportOfHazardousGoodsDesignApproval: 'Design approval for the transport of hazardous goods',
    specialPurpose: 'Special purpose of the vehicle',
  }
}
