export default {
  btn: {
    add: 'Додати',
    addEnvironmental: 'Додати випробування',
    correctly: 'Вірно',
    wrong: 'Невірно',
    comment: 'Коментар',
    endModeration: 'Закінчити модерацію',
    confirmInformation: 'Підтвердити інформацію',
    sendToRevision: 'Відправити на доопрацювання',
    nextStep: 'Наступний крок',
    prevStep: 'Попередній Крок',
    sign: 'Підписати',
    clear: 'Очистити',
    toMain: 'На Головну',
    leaveComment: 'Залишити Коментар',
    close: 'Закрити',
    openInNewTab: 'Відкрити в новому вікні',
    cancel: 'Скасувати',
    save: 'Зберегти',
    set: 'Застосувати',
    search: 'Шукати',
    edit: 'Редагувати',
    addTire: 'Додати колесо',
    addAxes: 'Додати вісь',
    addNoiseTesting: 'Додати випробування шуму',
    addSmokeTesting: 'Додати випробування димності',
    addGroupAxle: 'Додати групу вісей',
    logout: 'Вийти',
    profile: 'Кабінет',
    createCertificateType: 'Створити сертифікат типу',
    createUser: 'Додати користувача реєстру',
    addUser: 'Додати користувача',
    updateUser: 'Оновити дані користувача',
    editUser: 'Редагувати дані користувача',
    changePassword: 'Змінити пароль',
    changeStatus: 'Змінити статус',
    toUserList: 'До списку користувачів',
    public: 'Опубліковати',
    back: 'Назад',
    no: 'Ні',
    yes: 'Так'
  },
}
